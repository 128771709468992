import { FilterSelected, SegmentSelected } from '../../../../shared/new-components/hf-filter-menu/types';
import { toSegmentParams } from '../../../engagement/shared/utils';

export interface CommonParams {
    filters?: string;
    groupId?: string[];
    hierarchyId?: string[];
    hasCompanyReply?: boolean;
    hasReplies?: boolean;
    hasConversation?: boolean;
    privated?: boolean;
    hasSignature?: boolean;
    feedbackTypes?: string[];
    positivenessFrom?: number;
    positivenessTo?: number;
}

export const buildCommonParams = (filters: FilterSelected): CommonParams => {


    const params: CommonParams = {};

    if (filters?.segmentIds && Array.isArray(filters.segmentIds)) {
        params.filters = toSegmentParams(filters.segmentIds as SegmentSelected[]);
    }
    if (filters?.groupIds) {
        params.groupId = filters.groupIds as string[];
    }
    if (filters?.hierarchyIds) {
        params.hierarchyId = filters.hierarchyIds as string[];
    }
    if (filters?.feedbackTypes ) {
        params.feedbackTypes = filters.feedbackTypes as string[];
    } else {
        params.feedbackTypes = ['INFORMATION', 'CONGRATULATION', 'SUGGESTION', 'CRITICISM'];
    }

    // activity filters
    if (filters?.hasCompanyReply?.[0] === 'YES') { params.hasCompanyReply = true; }
    if (filters?.hasCompanyReply?.[0] === 'NO') { params.hasCompanyReply = false; }

    if (filters?.hasEmployeeReplies?.[0] === 'YES') { params.hasReplies = true; }
    if (filters?.hasEmployeeReplies?.[0] === 'NO') { params.hasReplies = false; }

    if (filters?.hasConversation?.[0] === 'YES') { params.hasConversation = true; }
    if (filters?.hasConversation?.[0] === 'NO') { params.hasConversation = false; }

    if (filters?.isPrivate?.[0] === 'YES') { params.privated = true; }
    if (filters?.isPrivate?.[0] === 'NO') { params.privated = false; }

    if (filters?.isSigned?.[0] === 'YES') { params.hasSignature = true; }
    if (filters?.isSigned?.[0] === 'NO') { params.hasSignature = false; }

    // positivity filters
    if (typeof filters?.positivenessFrom === 'number') {
        params.positivenessFrom = filters.positivenessFrom;
    }
    if (typeof filters?.positivenessTo === 'number') {
        params.positivenessTo = filters.positivenessTo;
    }


    if (filters.from) {
        params.from = filters.from;
    }
    if (filters.to) {
        params.to = filters.to;
    }

    return params;
};

export const buildTagsParams = (filters: FilterSelected, searchText?: string) => {
    return {
        ...buildCommonParams(filters),
        commentText: searchText || undefined
    };
};

export const buildCommentsParams = (
    filters: FilterSelected,
    searchText: string,
    currentPage: number,
    sortOrder: string,
    activeTags: string[],
    currentVoeCategory: string | null
) => {
    return {
        ...buildCommonParams(filters),
        fromComment: (currentPage - 1) * 10,
        toComment: currentPage * 10,
        sortOrder: sortOrder,
        tags: activeTags,
        commentText: searchText || undefined,
        voeCategories: currentVoeCategory
    };
};
