import React, {useState} from 'react';
import {Button, Stack} from '@mui/material';
import {getService} from '../../../migration_utils/react-in-angular';
import UserListing from './components/user_listing';
import UserSearchBar from './components/search_bar';
import {FilterSelected} from '../../shared/new-components/hf-filter-menu/types';
import ManageUsersStats from './components/stats_bar';
import {WhiteBgPaper} from '../../shared/new-components/hf-styled-components/WhiteBgPaddedPapper';
import DashboardHeader from '../../shared/components/_migrated/dashboard-header/hfDashboardHeader';

import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';


/*
* ManageUsersView component holds all the components for inviting and managing users
* on the platform
 */
const ManageUsersView = () => {

    const People = getService('People');
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');


    const [loading, setLoading] = useState(false);
    const [showInvite, setShowInvite] = React.useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = React.useState<FilterSelected>(
        {
            deletedStatus: [false],
            activatedStatus: [true, false],
            employeeType: ['EMPLOYEE', 'ADMIN', 'MANAGER'],
            mailStatus: ['ANY']
        }
    );
    const [searchText, setSearchText] = React.useState<string | null>('');


    /*
    * Export employees to excel by calling the People service
     */
    const exportEmployeesToExcel = () => {
        setLoading(true);
        People.getTemplate('UPDATE', (err: any, template: any) => {
            setLoading(false);
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                const blob = new Blob([template], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'users.xlsx';
                link.click();
            }
        });
    };

    const navigateToBulk = () => {
        getService('$state').go('manage.users_bulk');
    };




    return (
        <Stack gap={2}>


            <DashboardHeader
                hfTitle={$translate.instant('EMPLOYEES_SECTION_TITLE')}
                hfSubtitle={$translate.instant('EMPLOYEES_SECTION_DESCRIPTION')}
                actions={
                    <Stack direction='row' spacing={2}>

                        <Button
                            startIcon={<GroupAddRoundedIcon />}
                            variant='contained'
                            onClick={navigateToBulk}
                        >
                            {$translate.instant('PROCESS_EMPLOYEE_FILE')}
                        </Button>
                    </Stack>
                }
            />





            <ManageUsersStats
                setShowingInvite={() => {setShowInvite(true); }}
            />

            <WhiteBgPaper>
                <UserSearchBar
                    onSearchFieldsUpdated={setSelectedFilter}
                    onSearchTextUpdated={setSearchText}
                    searchText={searchText}
                    filters={selectedFilter}
                />
                <UserListing filters={selectedFilter}
                    isInviting={showInvite}
                    searchText={searchText}
                    onInvitingCancel={() => {
                        setShowInvite(false);
                    }}/>
            </WhiteBgPaper>


        </Stack>
    );
};



export default ManageUsersView;
