import * as React from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    Stack,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import {RepliesTypeComment} from '../../types';
import {TitleCloseableDrawer} from '../../../hf-title-closeable-drawer/hfTiteCloseableDrawer';
import {getService} from '../../../../../../migration_utils/react-in-angular';
import CommentTypeHandler from '../../comment-type-handler/CommentTypeHandler';
import CommentReplyType from '../../comment-reply/CommentReplyType';
import {useState} from 'react';
import {HfBannerReact} from '../../../hf-banner/hfBanner';



interface CommentRepliesDrawerProps {
    comment: RepliesTypeComment;
    showDrawer: boolean;
    onClose: () => void;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;
}

const CommentRepliesDrawer = ({ comment, showDrawer,  onClose, onCommentUpdated } : CommentRepliesDrawerProps) => {


    const [replyMessage, setReplyMessage] = useState('');
    const [signReply, setSignReply] = useState(true);
    const [posting, setPosting] = useState(false);

    // tODO: We are doing a trick here to avoid the modal to be closed when the user replies
    /*
        1. Clone the original comment
        2. Track any changes in the replies
        3. Only call the onCommentUpdated when the replies change and the modal is closed.
        This will avoid the modal to be closed when the user replies due re-rendering
     */

    const [internalComment, setInternalComment] = useState(comment);

    const [hasRepliesChanged, setHasRepliesChanged] = useState(false);




    const Comments = getService('Comments');
    const $translate = getService('$translate');
    const ErrorSvrc = getService('ErrorSvrc');

    const renderReplies = () => {

        if (internalComment?.replies) {
            return (
                internalComment?.replies.map((reply, index) => (
                    <Box key={index} sx={{marginBottom: 1}}>
                        <CommentReplyType reply={reply}
                            showModerateLink={true}
                            replyOf={comment.id}
                            onCommentUpdated={onCommentUpdated}/>
                    </Box>
                ))

            );
        } else {
            return null;
        }
    };

    const handleSendReply = () => {



        setPosting(true);
        Comments.sendReply(internalComment.id, replyMessage, signReply, (err, updatedComment) => {
            setPosting(false);
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                setReplyMessage('');
                setSignReply(false);
                setInternalComment(updatedComment);
                setHasRepliesChanged(true);

            }
        });

    };

    const handleClose = () => {

        /*
            Reset the reply form
         */
        onClose();
        setReplyMessage('');
        setSignReply(true);

        if (hasRepliesChanged) {
            onCommentUpdated(null, internalComment.id);
            setHasRepliesChanged(false);
        }
    };

    const renderReplyForm = () => {
        /*  For private comments, we don't allow replies */
        if (comment.privated) {
            return (
                <Box>
                    <HfBannerReact type={'warning'} sx={{maxHeight: '60px'}} >
                        <Typography variant={'body3'}>
                            {$translate.instant('COMMENT_IS_PRIVATED_REPLY_IN_THREAD_ACTION_HINT')}
                        </Typography>
                    </HfBannerReact>
                </Box>

            );
        } else if (!internalComment.allowReply) {
            return (
                <HfBannerReact type={'warning'} sx={{maxHeight: '60px'}}>
                    <Typography variant={'body3'}>
                        {$translate.instant('COMMENT_REPLY_AS_MANAGER_ALREADY_REPLIED')}
                    </Typography>
                </HfBannerReact>
            );
        } else {
            return (
                <Stack direction={'column'} spacing={1} width={'100%'}>

                    <Typography variant={'h6'}>
                        {$translate.instant('COMMENT_REPLY_AS_MANAGER')}
                    </Typography>


                    <TextField
                        fullWidth
                        size={'small'}
                        multiline
                        rows={2}
                        variant='outlined'
                        placeholder={$translate.instant('COMMENT_REPLY_AS_MANAGER')}
                        value={replyMessage}
                        onChange={(e) => setReplyMessage(e.target.value)}
                    />

                    <Stack direction={'row'} spacing={1}>
                        {internalComment.allowNonAnonymousReply && (
                            <Box sx={{display: 'flex' }}>
                                <FormControlLabel
                                    control={
                                        <Switch checked={signReply}
                                            onChange={(e) => setSignReply(e.target.checked)}
                                        />

                                    }
                                    label={
                                        <Typography variant={'body3'}>
                                            {$translate.instant('COMMENT_REPLY_AS_MANAGER_SIGN_REPLY_TITLE')}
                                        </Typography>
                                    }
                                />
                            </Box>

                        )}

                        <Button
                            fullWidth
                            variant='contained'
                            color='primary'
                            disabled={posting || !replyMessage.trim()}
                            onClick={handleSendReply}
                        >
                            {posting ? (
                                <CircularProgress size={24} />
                            ) : (
                                $translate.instant('SEND_CONVERSATION_REPLY')
                            )}
                        </Button>
                    </Stack>





                </Stack>
            );
        }


    };

    return (
        <TitleCloseableDrawer
            title={$translate.instant('REPLIES')}
            anchor={'right'}
            open={showDrawer}
            onClose={handleClose}
            drawerProps={{
                sx: {
                    width: '400px',
                    maxWidth: 'calc(100vw - 100px)',
                    minWidth: 'calc(400px)',
                    zIndex: 3000}
            }}
        >
            <Stack spacing={2}
                height={'100%'}>
                <CommentTypeHandler comment={internalComment}
                    showReplyWithAnnouncementLink={false}
                    showConversationLink={false}
                    onCommentUpdated={onCommentUpdated}/>

                <Divider/>

                <Typography variant={'caption'} fontWeight={'bold'}>
                    {$translate.instant('N_REPLIES', {replies: internalComment?.replies ? internalComment.replies.length : 0})}
                </Typography>


                <Box sx={{
                    flexGrow: 1,
                    overflowY: 'auto'
                }}>
                    <Box sx={{
                        paddingLeft: 1,
                        borderLeft: theme => `1px solid ${theme.palette.divider}`,
                    }}>

                        {renderReplies()}
                    </Box>

                </Box>


                <Divider/>

                {renderReplyForm()}

            </Stack>
        </TitleCloseableDrawer>
    );
};

export default CommentRepliesDrawer;
