import * as React from 'react';
import { getService } from 'reactInAngular';
import {
    Box,
    Stack,
    Typography,
    Pagination,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    SelectChangeEvent,
    Divider,
    Button,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { WhiteBgPaper } from '../../../shared/new-components/hf-styled-components/WhiteBgPaddedPapper';
import CommentTypeHandler from '../../../shared/new-components/hf-comments/comment-type-handler/CommentTypeHandler';
import { CommentBase } from '../../../shared/new-components/hf-comments/types';
import FeedbackStatsComponent from './components/FeedbackStatsComponent';
import { HfBannerReact } from '../../../shared/new-components/hf-banner/hfBanner';
import FeedbackTagsComponent from './components/FeedbackTagsComponent';
import DashboardHeader from '../../../shared/components/_migrated/dashboard-header/hfDashboardHeader';
import HFSearchInput from '../../../shared/components/_migrated/search-input/hfSearchInput';
import { FilterSelected } from '../../../shared/new-components/hf-filter-menu/types';
import FeedbackFilters from './components/FeedbackFilters';
import EmptyState from '../../../shared/components/_migrated/empty_state/empty_state';
import { FeedbackStats } from '../../../shared/types/feedback';
import FeedbackVOEChart from './components/FeedbackVOEChart';
import { buildCommentsParams } from './utils/buildParams';
import FeedbackPositivenessChart from './components/FeedbackPositivenessChart';
import { useFeedbackReportTypeModal } from './components/FeedbackReportTypeModal';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';

type SortOrder = 'RECENT' | 'AGREE' | 'DISAGREE' | 'POPULARITY' | 'LAST_UPDATED' | 'INAPPROPRIATE' | 'REFEREED';

interface CommentsData {
    comments: CommentBase[];
    total: number;
}

const FeedbackCommentsView: React.FC = () => {
    const [loading, setLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalComments, setTotalComments] = React.useState(0);
    const [comments, setComments] = React.useState<CommentBase[]>([]);
    const [sortOrder, setSortOrder] = React.useState<SortOrder>('RECENT');
    const [stats, setStats] = React.useState<FeedbackStats | null>(null);
    const [activeTags, setActiveTags] = React.useState<string[]>([]);
    const [searchText, setSearchText] = React.useState('');
    const [currentVoeCategory, setCurrentVoeCategory] = React.useState<string | null>(null);
    const [filters, setFilters] = React.useState<FilterSelected>({
        from: new Date(new Date().setMonth(new Date().getMonth() - 3)),
        to: new Date(),
        hasCompanyReply: ['ALL'],
        hasEmployeeReplies: ['ALL'],
        hasConversation: ['ALL'],
        isPrivate: ['ALL'],
        isSigned: ['ALL'],
        hierarchyIds: [],
        groupIds: [],
        segmentIds: []
    });

    const [params, setParams] = React.useState<any>();
    const [exportingExcelReport, setExportingExcelReport] = React.useState(false);
    const { showCommentsReportModal, ModalComponent } = useFeedbackReportTypeModal();

    const $translate = getService('$translate');
    const ErrorSvrc = getService('ErrorSvrc');
    const Comments = getService('Comments');
    const AuthSrvc = getService('AuthSrvc');

    React.useEffect(() => {
        const params = buildCommentsParams(filters, searchText, currentPage, sortOrder, activeTags, currentVoeCategory);
        setParams(params);
    }, [currentPage, sortOrder, filters, activeTags, searchText, currentVoeCategory]);

    const loadData = React.useCallback(async () => {
        setLoading(true);
        try {
            // get comments
            const commentsData = await new Promise<CommentsData>((resolve, reject) => {
                Comments.get(params, resolve, reject);
            });

            setComments(commentsData.comments);
            setTotalComments(commentsData.total);
        } catch (error) {
            ErrorSvrc.showErrorModal(error);
        } finally {
            setLoading(false);
        }
    }, [params]);

    React.useEffect(() => {
        loadData();
    }, [loadData]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const handleCommentUpdated = (err: any, updatedCommentId: string) => {
        if (!err && updatedCommentId) {
            loadData();
        }
    };

    const handleSearchChange = (searchText: string) => {
        setSearchText(searchText);
        setCurrentPage(1);
    };

    const handleExportToExcel = () => {
        setExportingExcelReport(true);
        const params = buildCommentsParams(filters, searchText, currentPage, sortOrder, activeTags, currentVoeCategory);

        showCommentsReportModal(params, () => {
            setExportingExcelReport(false);
        });
    };

    const renderSortComponent = () => {
        const handleSortChange = (event: SelectChangeEvent<SortOrder>) => {
            setSortOrder(event.target.value as SortOrder);
            setCurrentPage(1); // reset to first page when sorting changes
        };

        return (
            <Box sx={{ minWidth: 200 }}>
                <FormControl fullWidth size='small'>
                    <InputLabel>{$translate.instant('SORT_BY')}</InputLabel>
                    <Select
                        value={sortOrder}
                        label={$translate.instant('SORT_BY')}
                        onChange={handleSortChange}
                    >
                        <MenuItem value='RECENT'>{$translate.instant('RECENT')}</MenuItem>
                        <MenuItem value='AGREE'>{$translate.instant('AGREE')}</MenuItem>
                        <MenuItem value='DISAGREE'>{$translate.instant('DISAGREE')}</MenuItem>
                        <MenuItem value='POPULARITY'>{$translate.instant('POPULARITY')}</MenuItem>
                        <MenuItem value='LAST_UPDATED'>{$translate.instant('LAST_UPDATED')}</MenuItem>
                        {AuthSrvc.userHasRole('ADVISOR') && (
                            <MenuItem value='STARRED'>{$translate.instant('STARRED')}</MenuItem>
                        )}
                        <MenuItem value='INAPPROPRIATE'>{$translate.instant('REPORTED_COMMENT')}</MenuItem>
                        <MenuItem value='REFEREED'>{$translate.instant('MARKED_AS_REFEREED')}</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        );
    };

    return (
        <Stack gap={2}>
            <DashboardHeader
                hfTitle={$translate.instant('COMMENTS')}
                hfSubtitle={$translate.instant('COMMENTS_SUBTITLE')}
                actions={

                    <Button
                        variant='contained'
                        startIcon={<FileDownloadRoundedIcon />}
                        onClick={handleExportToExcel}
                        disabled={exportingExcelReport || loading}
                    >
                    </Button>


                }
            />
            {stats?.refereed > 0 && (
                <HfBannerReact>
                    <Box display='flex' alignItems='center' justifyContent='left' gap={1}>
                        <img className='img-sm' src='/images/fridge.png' alt='' style={{ height: 22 }} />
                        <Typography color='textSecondary' variant='body3'>
                            {$translate.instant('MARKED_AS_REFEREED_ADVICE', { number: stats?.refereed })}
                        </Typography>
                    </Box>
                </HfBannerReact>
            )}

            <Grid container spacing={2}>
                <Grid lg={4} md={12}>
                    <Stack gap={2}>
                        <WhiteBgPaper>
                            <Stack gap={2}>
                                <Typography variant='h2' fontWeight={'bold'}>
                                    {$translate.instant('COMMENTS_SEARCH')}
                                </Typography>

                                <HFSearchInput
                                    realTime={false}
                                    searchPlaceholder={$translate.instant('COMMENTS_SEARCH_TEXT')}
                                    searchText={searchText}
                                    onSearchTextChange={handleSearchChange}
                                />

                                <Typography variant='h2' fontWeight={'bold'}>
                                    {$translate.instant('FILTERS')}
                                </Typography>

                                <FeedbackFilters
                                    onFiltersChange={setFilters}
                                    filters={filters}
                                />
                            </Stack>
                        </WhiteBgPaper>

                        <WhiteBgPaper>
                            <Stack gap={2}>
                                <Stack gap={1}>
                                    <Typography variant='h2' fontWeight={'bold'}>
                                        {$translate.instant('VOICE_OF_EMPLOYEE_DISTRIBUTION')}
                                    </Typography>

                                    <Typography variant='body3' color='textSecondary'>
                                        {$translate.instant('VOICE_OF_EMPLOYEE_DISTRIBUTION_INSTRUCTION')}
                                    </Typography>
                                </Stack>

                                <FeedbackVOEChart
                                    filters={filters}
                                    searchText={searchText}
                                    onCategoryClick={setCurrentVoeCategory}
                                />

                                <Stack gap={1}>
                                    <Typography variant='h2' fontWeight={'bold'}>
                                        {$translate.instant('COMMENTS_TAGS')}
                                    </Typography>

                                    <Typography variant='body3' color='textSecondary'>
                                        {$translate.instant('COMMENTS_TAGS_INSTRUCTION')}
                                    </Typography>
                                </Stack>

                                <FeedbackTagsComponent
                                    activeTags={activeTags}
                                    onTagsChange={setActiveTags}
                                    availableTags={stats?.concepts || []}
                                />
                            </Stack>
                        </WhiteBgPaper>
                    </Stack>
                </Grid>
                <Grid lg={8} md={12}>
                    <Stack gap={2}>
                        <Stack gap={2} direction='row'>
                            <WhiteBgPaper sx={{ flex: 1 }}>
                                <Stack gap={2}>
                                    <Box>
                                        <Typography variant='h2' fontWeight={'bold'}>
                                            {$translate.instant('COMMENTS_RESULTS_FEEDBACK_TYPES')}
                                        </Typography>

                                        <Typography variant='body3' color='textSecondary'>
                                            {$translate.instant('COMMENTS_RESULTS_FEEDBACK_TYPES_SUBTITLE')}
                                        </Typography>
                                    </Box>

                                    <FeedbackStatsComponent
                                        filters={filters}
                                        searchText={searchText}
                                        voeCategory={currentVoeCategory}
                                        onStatsLoaded={setStats}
                                    />
                                </Stack>
                            </WhiteBgPaper>

                            <WhiteBgPaper>
                                <Stack gap={2}>
                                    <Box>
                                        <Typography variant='h2' fontWeight={'bold'}>
                                            {$translate.instant('POSITIVENESS_DISTRIBUTION')}
                                        </Typography>

                                        <Typography variant='body3' color='textSecondary'>
                                            {$translate.instant('POSITIVENESS_DISTRIBUTION_INSTRUCTION')}
                                        </Typography>
                                    </Box>

                                    <FeedbackPositivenessChart stats={stats} />
                                </Stack>
                            </WhiteBgPaper>
                        </Stack>

                        <WhiteBgPaper>
                            <Stack spacing={2}>
                                <Box display='flex' justifyContent='space-between' alignItems='center'>
                                    <Typography variant='h2'>
                                        {$translate.instant('COMMENTS')}
                                    </Typography>
                                </Box>

                                <Stack gap={2} direction='row' justifyContent='space-between'>
                                    <Stack gap={1} direction='column'>
                                        <Typography variant='body3' fontWeight={'bold'}>
                                            {totalComments} {$translate.instant('COMMENTS')}
                                        </Typography>
                                        <Typography variant='body3' color='textSecondary'>
                                            {stats?.authors} {$translate.instant('COMMENTS_FROM_AUTHORS')}
                                        </Typography>
                                    </Stack>

                                    {renderSortComponent()}
                                </Stack>

                                {loading ? (
                                    <Box display='flex' justifyContent='center'>
                                        <Typography>
                                            {$translate.instant('LOADING_DATA_SENTENCE_1')}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Stack spacing={2}>
                                        {comments?.length > 0 ? (
                                            comments.map((comment: CommentBase) => (
                                                <Stack key={comment.id} gap={2} paddingBottom={2}>
                                                    <CommentTypeHandler
                                                        comment={comment}
                                                        onCommentUpdated={handleCommentUpdated}
                                                    />
                                                    <Box display='flex' justifyContent='center'>
                                                        <Divider sx={{ width: '90%' }} />
                                                    </Box>
                                                </Stack>
                                            ))
                                        ) : (
                                            <EmptyState
                                                image='/images/no_feedback_engagement.svg'
                                                message={$translate.instant('NO_COMMENTS_MESSAGE')}
                                                submessage={$translate.instant('NO_COMMENTS_SUBMESSAGE')}
                                            />
                                        )}

                                        {totalComments > 10 && (
                                            <Box display='flex' justifyContent='center'>
                                                <Pagination
                                                    count={Math.ceil(totalComments / 10)}
                                                    page={currentPage}
                                                    onChange={handlePageChange}
                                                />
                                            </Box>
                                        )}
                                    </Stack>
                                )}
                            </Stack>
                        </WhiteBgPaper>
                    </Stack>
                </Grid>
            </Grid>

            <ModalComponent />
        </Stack>
    );
};

export default FeedbackCommentsView;
