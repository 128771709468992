import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';
import { angularize } from 'reactInAngular';
import {
    Box,
    TextField,
    ImageList,
    ImageListItem,
    CircularProgress,
    Stack,
    Skeleton
} from '@mui/material';
import angular from 'angular';
import Style from '../../../utilities/style.utils';
import 'react-image-crop/dist/ReactCrop.css';

interface ImageItem {
    image: string;
    author: string;
    selected?: boolean;
}

interface HFImageSearchProps {
    currentImage?: string;
    defaultImage?: string;
    onSelect?: (image: string | File | null) => void;
    small?: boolean;
    numResults?: number;
}

const HFImageSearch: React.FC<HFImageSearchProps> = ({
    currentImage,
    defaultImage,
    onSelect,
    small = false,
    numResults = 7
}) => {
    const [imageSearchName, setImageSearchName] = useState('');
    const [loading, setLoading] = useState(false);
    const [foundImages, setFoundImages] = useState<ImageItem[]>([]);

    const UnsplashService = getService('UnsplashService');
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');
    const searchImages = () => {
        if (!imageSearchName) {
            setFoundImages([{
                image: defaultImage || '',
                author: 'HappyForce',
                selected: false
            }]);
            return;
        }

        setLoading(true);
        UnsplashService.searchImages(imageSearchName, numResults, (err: any, results: ImageItem[]) => {
            setLoading(false);
            if (err) {
                ErrorSvrc.showErrorModal(err);
                return;
            }

            const images = [...results];
            if (defaultImage) {
                images.unshift({
                    image: defaultImage,
                    author: 'HappyForce',
                    selected: !currentImage
                });
            }
            setFoundImages(images);
        });
    };

    const handleImageSelect = (selectedImage: ImageItem) => {
        const updatedImages = foundImages.map(image => ({
            ...image,
            selected: image.image === selectedImage.image ? !image.selected : false
        }));
        setFoundImages(updatedImages);

        if (onSelect) {
            onSelect(selectedImage.selected ? null : selectedImage.image);
        }
    };

    useEffect(() => {
        searchImages();
    }, [imageSearchName]);

    return (
        <Stack spacing={2}>
            <TextField
                fullWidth
                variant='outlined'
                value={imageSearchName}
                onChange={(e) => setImageSearchName(e.target.value)}
                size={small ? 'small' : 'medium'}
                placeholder={$translate.instant('IMAGE_SELECT_SEARCH_PLACEHOLDER')}
            />


            {loading ? (
                <ImageList cols={small ? 2 : 4} gap={8}>
                    {/* Add the default image */}
                    <ImageListItem>
                        <img
                            src={defaultImage}
                            alt='Default image'
                            loading='lazy'
                            style={{
                                width: '100%',
                                height: small ? '100px' : '200px',
                                objectFit: 'cover'
                            }}
                        />
                    </ImageListItem>

                    {[...Array(numResults - 1)].map((_, index) => (
                        <ImageListItem key={index}>
                            <Skeleton
                                variant='rectangular'
                                width='100%'
                                height={small ? 100 : 200}
                                sx={{ borderRadius: theme => `${theme.shape.borderRadius}px` }}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            ) : (
                <ImageList cols={small ? 2 : 4} gap={8}>
                    {foundImages.map((image, index) => (
                        <ImageListItem
                            key={index}
                            onClick={() => handleImageSelect(image)}
                            sx={theme => ({
                                cursor: 'pointer',
                                border: image.selected ? `4px solid ${Style.Blue400}` : 'none',
                                borderRadius: `${theme.shape.borderRadius}px`,
                                '&:hover': {
                                    opacity: 0.8
                                }
                            })}
                        >
                            <img
                                src={image.image}
                                alt={`By ${image.author}`}
                                loading='lazy'
                                style={{
                                    width: '100%',
                                    height: small ? '100px' : '200px',
                                    objectFit: 'cover'
                                }}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            )}



        </Stack>
    );
};




/* This is our trick for expose these components on angular */
angularize(HFImageSearch, 'hfImageSearch', angular.module('happyForceApp'), {
    currentImage: '<',
    defaultImage: '<',
    onSelect: '<',
    small: '<',
    numResults: '<'
});

export default HFImageSearch;
