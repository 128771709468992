import React, { useState, useEffect, MouseEvent } from 'react';
import {angularize} from 'reactInAngular';
import angular from 'angular';
import { Chip } from '@mui/material';


interface HFTagProps {
    type?: string;
    status?: string;
    value?: any;
    text?: string | React.ReactNode;
    color?: string;
    textColor?: string;
    iconClass?: string;
    onRemove?: () => void;
    onPress?: (event?: MouseEvent<HTMLElement>) => void;
}

const HFTag = ({ type, status, value, text, color, textColor, iconClass, onRemove, onPress } : HFTagProps) => {
    const [statusClass, setStatusClass] = useState< 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'>('primary');
    const [isButton, setIsButton] = useState<boolean>(false);
    const [finalTextColor, setFinalTextColor] = useState<string>('');

    useEffect(() => {
        const swapClass = () => {
            let newStatusClass = '';

            if (!color) {
                switch (status) {
                    case 'success':
                        newStatusClass = 'success';
                        break;
                    case 'warning':
                        newStatusClass = 'warning';
                        break;
                    case 'danger':
                    case 'error':
                        newStatusClass = 'error';
                        break;
                    case 'neutral':
                        newStatusClass = 'default';
                        break;
                    case 'primary':
                    default:
                        newStatusClass = 'primary';
                }
            }

            setStatusClass(newStatusClass);
        };

        swapClass();

        if (onPress) {
            setIsButton(true);
        }
    }, [color, status, onPress, textColor]);

    const handleRemove = (event: MouseEvent<HTMLElement>) => {
        if (onRemove) {
            onRemove();
            event.stopPropagation();
        }
    };

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        if (onPress) {
            onPress(event);
            event.stopPropagation();
        }
    };

    const style = color ? { backgroundColor: color, color: textColor } : {};


    const getIcon = (type: string) => {
        switch (type) {
            case 'icon':
                return <i className={`icon-mark fa ${iconClass}`} />;
            case 'bullet':
                return <span className='bullet-mark'>&bull;</span>;
            default:
                return null;
        }
    };


    if (statusClass) {
        return (
            <Chip
                label={text}
                color={statusClass}
                variant='filled'
                icon={getIcon(type)}
                size='small'
                onClick={onPress ? handleClick : undefined}
                onDelete={onRemove ? handleRemove : undefined}
            />);
    } else {
        return (
            <Chip
                label={text}
                sx={{
                    backgroundColor: color,
                    color: finalTextColor,
                }}
                variant='filled'
                icon={getIcon(type)}
                size='small'
                onClick={onPress ? handleClick : undefined}
                onDelete={onRemove ? handleRemove : undefined}
            />);
    }

    return (
        <span className='hf-tag'>
            <div
                role={isButton ? 'button' : undefined}
                className={`mini-style content ${statusClass}`}
                onClick={handleClick}
                style={style}
            >
                {type === 'icon' && (
                    <i className={`icon-mark fa ${iconClass}`} />
                )}
                {type === 'bullet' && (
                    <span className='bullet-mark'>&bull;</span>
                )}
                {typeof text === 'string' ? (
                    <span dangerouslySetInnerHTML={{ __html: text }}></span>
                ) : (
                    text
                )}
                {onRemove && (
                    <i role='button' className='remove-mark fa fa-remove' onClick={handleRemove} />
                )}
            </div>
        </span>
    );
};


// placeholder for angularize function
angularize(HFTag, 'hfTag', angular.module('happyForceApp'), {
    type: '@?',
    status: '<',
    value: '<',
    text: '@',
    color: '<',
    textColor: '<',
    iconClass: '@',
    onRemove: '&?',
    onPress: '&?',
});

export default HFTag;
