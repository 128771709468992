
//return a workable RGB int array [r,g,b] from hex representation
function processHEX(val) {
    //does the hex contain extra char?
    var hex = (val.length > 6) ? val.substr(1, val.length - 1) : val;

    // is it a six character hex?
    if (hex.length > 3) {

        //scrape out the numerics
        var r = hex.substr(0, 2);
        var g = hex.substr(2, 2);
        var b = hex.substr(4, 2);

        // if not six character hex,
        // then work as if its a three character hex
    } else {

        // just concat the pieces with themselves
        var r = hex.substr(0, 1) + hex.substr(0, 1);
        var g = hex.substr(1, 1) + hex.substr(1, 1);
        var b = hex.substr(2, 1) + hex.substr(2, 1);

    }

    // return our clean values
    return {
        red: parseInt(r, 16),
        green: parseInt(g, 16),
        blue: parseInt(b, 16)
    };
}



var Style = {};

Style.colors = ['#F44336', '#2196F3', '#8BC34A', '#FF5722', '#E91E63', '#03A9F4', '#CDDC39', '#795548', '#9C27B0'];

Style.chartColors = ['#4A90E2', '#71CA00', '#67748A', '#FF9300', '#DF356B', '#6C70DC', '#FF1C1C', '#37C098', '#FFC200',
    '#D2E3F8', '#DBF2BF', '#CCD1D8', '#FFE4BF', '#F7CDDA', '#DADBF6', '#FFC6C6', '#CDEFE5', '#FFF0BF'];

Style.chartFontFamily = 'Helvetica';
Style.chartFontWeight = 400;

Style.linkColor = '#0079ff';

Style.faceHappy = '#ffba18';
Style.faceGood = '#37c098';
Style.faceBad = '#6c70dc';
Style.faceSad = '#d92c32';

Style.excellent = '#71CA00';
Style.good = '#71CA00';
Style.warning = '#FF9300';
Style.neutral = '#99A2B1';
Style.bad = '#FF1C1C';
Style.noData = '#99A2B1';

/*Style.excellent = '#0CAD00';
Style.good = '#0CAD00';
Style.warning = '#FF9300';
Style.neutral = '#C8CBCC';
Style.bad = '#FE2847';
Style.noData = '#BBBBBB';*/

// -Style.good = '#4a90e2';
// -Style.warning = '#FFC200';
// -Style.neutral = '#c8cbcc';
// -Style.bad = '#FF3700';

Style.goodNonRelevant = '#B8E580';
Style.warningNonRelevant = '#FFC980';
Style.neutralNotRelevant = '#CCD1D8';
Style.badNonRelevant = '#FF8D8D';
Style.noDataNonRelevant = '#CCD1D8';

/*
Style.goodNonRelevant = '#0B9C00';
Style.warningNonRelevant = '#FB7800';
Style.neutralNotRelevant = '#ACACAC';
Style.badNonRelevant = '#EA0022';
Style.noDataNonRelevant = '#BBBBBB';

 */


Style.criticismColor = '#ECD3BB';
Style.recognitionColor = '#96d600';
Style.congratulationColor = '#FF3700';
Style.suggestionColor = '#FFC200';
Style.informationColor = '#4A90E2';
Style.otherColor = '#303233';

Style.commentedColor = '#FFC200';
Style.notCommentedColor = '#DF356B';
Style.chartBg = '#EEEEEE';
Style.white = '#FFFFFF';


Style.heatmapColors = [
    '',
    '#FF3700',
    'rgba(217,44,50,0.75)',
    'rgba(217,44,50,0.25)',
    'rgba(200,203,204,0.25)',
    'rgba(200,203,204,0.75)',
    '#c8cbcc',
    'rgba(150,214,0,0.25)',
    'rgba(150,214,0,0.75)',
    '#0CAD00'
];

Style.questionColors = [
    '#FFC200',
    '#8FC246',
    '#6C70DC',
    '#DF356B',
    '#3EBFDE',
    '#EC7F4A',
    '#7B955D',
    '#9F9F9F',
    '#4B89D2',
    '#A87446',
    '#C63A0E',
    '#B330D3',
    '#00A3FF',
    '#44C751',
    '#B50C71'
];

Style.recognitionColors = [
    '#FFC200',
    '#8FC246',
    '#6C70DC',
    '#DF356B',
    '#3EBFDE',
    '#EC7F4A',
    '#7B955D',
    '#9F9F9F',
    '#4B89D2',
    '#A87446',
    '#C63A0E',
    '#B330D3',
    '#00A3FF',
    '#44C751',
    '#B50C71'
];

Style.managerActivityColors = [
    '#FFC200',
    '#8FC246',
    '#6C70DC',
    '#DF356B',
    '#3EBFDE',
    '#EC7F4A',
    '#7B955D',
    '#9F9F9F',
    '#4B89D2',
    '#A87446',
    '#C63A0E',
    '#B330D3',
    '#00A3FF',
    '#44C751',
    '#B50C71'
];


Style.scoreLineColor = '#0CAD00';
Style.benchmarkLineColor = '#4A90E2';
Style.industryLineColor = '#FFC200';


Style.Neutral200 = '#CCD1D8';
Style.Neutral050 = '#F3F4F7';
Style.Blue200 = '#D2E3F8';
Style.Blue400 = '#4A90E2';



Style.converSationOwner = '#FFC200';


Style.colorGradient = function (fadeFraction, rgbColor1, rgbColor2, rgbColor3) {
    var color1 = processHEX(rgbColor1);
    var color2 = processHEX(rgbColor2);
    var fade = fadeFraction;

    // Do we have 3 colors for the gradient? Need to adjust the params.
    if (rgbColor3) {
        fade = fade * 2;

        // Find which interval to use and adjust the fade percentage
        if (fade >= 1) {
            fade -= 1;
            color1 = processHEX(rgbColor2);
            color2 = processHEX(rgbColor3);
        }
    }

    var diffRed = color2.red - color1.red;
    var diffGreen = color2.green - color1.green;
    var diffBlue = color2.blue - color1.blue;

    var gradient = {
        red: parseInt(Math.floor(color1.red + (diffRed * fade)), 10),
        green: parseInt(Math.floor(color1.green + (diffGreen * fade)), 10),
        blue: parseInt(Math.floor(color1.blue + (diffBlue * fade)), 10)
    };

    return 'rgb(' + gradient.red + ',' + gradient.green + ',' + gradient.blue + ')';
};


Style.heatmapColors = [];

// This is the color for 0 value, witch means: no value
Style.heatmapColors.push('');

// Build heatmapColors
for (var i = 1; i <= 10; i++) {
    Style.heatmapColors.push(Style.colorGradient((i / 10), Style.bad, Style.warning, Style.excellent));
}



Style.charts = {
    fontFamily: 'Helvetica',
    fontSmall: {
        size: '12px',
        weight: '400px'
    },
    primaryColor: Style.chartColors[0],
    textColor: '#585858'
};


export default Style;
