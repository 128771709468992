'use strict';

import Dates from "../../../../../utilities/date.utilities";

const ActivationReportExportModal = angular.module('happyForceApp')
    .factory('ActivationReportExportModal',
        function ($uibModal, $translate, Reports, ErrorSvrc, Analytics, Settings, toaster) {
            var methods = {};
            methods.showExportModal = function (from, to) {
                $uibModal.open({
                    backdrop: 'static',
                    resolve: {
                        groupingType: function () {
                            return 'HIERARCHIES';
                        },
                        groupByOptions: function () {
                            return [
                                { id: 'HIERARCHIES', label: $translate.instant('AREAS') },
                                { id: 'GROUPS', label: $translate.instant('GROUPS') },
                                { id: 'CHARACTERISTICS', label: $translate.instant('SEGMENTS') }
                            ];
                        },
                        from: function () { return from; },
                        to: function () { return to; },
                        dateFormat: function () {return Settings.defaultMonthDateFormat; }
                    },
                    controller: function ($scope, $uibModalInstance, groupingType, groupByOptions, from, to, dateFormat) {
                        $scope.groupingType = groupingType;
                        $scope.groupByOptions = groupByOptions;
                        $scope.from = from;
                        $scope.to = to;
                        $scope.dateFormat = dateFormat;
                        $scope.ok = function () {
                            $uibModalInstance.close({
                                groupingType: $scope.groupingType,
                                from: $scope.from,
                                to: $scope.to
                            });
                        };

                        $scope.cancel = function () {
                            $uibModalInstance.dismiss();
                        };
                    },
                    template: require('./activation_report_type_modal.html'),
                    controllerAs: '$ctrl'
                }).result.then(function (modalParams) {
                    var params = {};
                    params.groupingType = modalParams.groupingType;
                    var from =  Dates.firstDayOfMonth(modalParams.from);
                    var to = Dates.lastDayOfMonth(modalParams.to);

                    Reports.activation(from, to, params, function () {
                        toaster.pop('success', null, ($translate.instant('VOTES_EXPORT_SENT')));
                        Analytics.trackEvent('activation', 'excel_report', 'success');
                    }, function errorCallback(response) {
                        ErrorSvrc.showErrorModal(response);
                        Analytics.trackEvent('activation', 'excel_report', 'false');

                    });
                    console.log('Report sent');
                }, function (closeAction) {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };

            return methods;

        }
    );

export default ActivationReportExportModal;

