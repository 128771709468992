import React, { useState, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { getService } from 'reactInAngular';
import EngagementCharts from '../../../engagement.charts';
import Dates from '../../../../../utilities/date.utilities';
import Style from '../../../../../utilities/style.utils';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup, Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Stack, Typography
} from '@mui/material';
import { CharacteristicName, GroupName, HierarchyName } from '../../../../../shared/new-components/hf-segmentation-name/hfSegmentationName';
import async from 'async';
import { getHierarchyName } from '../../../../../shared/services/migrated/hierarchy.service';
import EmptyState from '../../../../../shared/components/_migrated/empty_state/empty_state';
import { FilterSelected } from 'src/app/shared/new-components/hf-filter-menu/types';

interface HiPositioningBoxProps {
    to: Date;
    navigateToDetail: (filters: FilterSelected) => void;
}

interface DataItem {
    hierarchyId?: string;
    groupId?: string;
    characteristic?: {
        characteristicId: string;
        value: string;
    };
    color?: string;
    selected?: boolean;
    name?: string;
}

const HiPositioningBox: React.FC<HiPositioningBoxProps> = ({ to, navigateToDetail }) => {
    const [positioningBy, setPositioningBy] = useState<string>('HIERARCHIES');
    const [data, setData] = useState<DataItem[]>([]);
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [bubbleChart, setBubbleChart] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [noTypes, setNoTypes] = useState<boolean>(false);

    const [noDataItems, setNoDataItems] = useState<[]>([]);

    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');
    const HIService = getService('HIService');
    const Groups = getService('Groups');
    const Characteristics = getService('Characteristics');
    const Languages = getService('Languages');

    const positioningByOptions = [
        { id: 'HIERARCHIES', label: $translate.instant('AREAS') },
        { id: 'GROUPS', label: $translate.instant('GROUPS') },
        { id: 'CHARACTERISTICS', label: $translate.instant('SEGMENTS') }
    ];

    const computeColor = (count: number): string => {
        const remainder = count % Style.colors.length;
        return Style.colors[remainder === 0 ? Style.colors.length - 1 : remainder - 1];
    };

    const renderChart = () => {
        setNoDataItems(data.filter(item => item.quality === 'NO_DATA' || item.nonEnoughEmployees));
        setAllSelected(data.every(item => item.selected));
        setBubbleChart(EngagementCharts.scorePositioningChart(data, 0, 100, 'Happiness Index', 'HI', gotoItemDetail));
    };

    const getSegmentationName = (segment: DataItem, callback: (err: any, name: string | null) => void) => {
        if (positioningBy === 'HIERARCHIES') {
            getHierarchyName(segment.hierarchyId!, (err, name) => {
                if (err) {
                    callback(err, null);
                } else {
                    callback(null, name);
                }
            });
        } else if (positioningBy === 'GROUPS') {
            callback(null, Groups.getGroupName(segment.groupId!));
        } else if (positioningBy === 'CHARACTERISTICS') {
            let name = '';
            const characteristic = Characteristics.getCharacteristicById(segment.characteristic!.characteristicId);
            if (characteristic) {
                name = `${characteristic.description}-${Languages.getTranslationFromUserLanguage(
                    Characteristics.getCharacteristicValueName(segment.characteristic!.characteristicId, segment.characteristic!.value)
                )}`;
            }
            callback(null, name);
        }
    };

    const loadResults = () => {
        setLoading(true);
        const params = {
            from: Dates.firstDayOfMonth(to),
            to: Dates.lastDayOfMonth(to)
        };

        HIService.stats.grouping(positioningBy, params, (err: any, response: any) => {
            if (err) {
                return ErrorSvrc.showErrorModal(err);
            } else {
                const hasData = response && response.results;
                setNoTypes(response.noTypes);
                if (hasData && response.results.length) {
                    let count = 0;
                    const stream: any[] = [];
                    response.results
                        .filter((item: DataItem) => (item.hierarchyId || item.groupId || item.characteristic))
                        .forEach((item: DataItem) => {
                            stream.push((next: (err: any, result: DataItem | null) => void) => {
                                const finalItem = {
                                    ...item,
                                    color: computeColor(++count),
                                    selected: true
                                };
                                getSegmentationName(item, (err, name) => {
                                    if (err) {
                                        next(err, null);
                                    } else {
                                        finalItem.name = name;
                                        next(null, finalItem);
                                    }
                                });
                            });
                        });

                    async.parallel(stream, (err, results) => {
                        if (err) {
                            ErrorSvrc.showErrorModal(err);
                            return;
                        } else {
                            setData(results);
                        }
                    });
                }
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        renderChart();
    }, [data, allSelected]);

    const selectAll = () => {
        const isSelectAll = !allSelected;
        setData(data.map(item => ({ ...item, selected: isSelectAll })));
    };

    const gotoItemDetail = (item: DataItem) => {

        const linkParams: FilterSelected = {
            hierarchyIds: [],
            segmentIds: [],
            groupIds: [],
        };

        if (item.hierarchyId) {
            (linkParams.hierarchyIds as string[]).push(item.hierarchyId);
        } else if (item.groupId) {
            (linkParams.groupIds as string[]).push(item.groupId);
        } else if (item.characteristic) {
            (linkParams.segmentIds as string[]).push(item.characteristic.characteristicId + ':' + item.characteristic.value);
        }
        navigateToDetail(linkParams);
    };

    useEffect(() => {
        loadResults();
    }, [to, positioningBy]);

    const handleSelect = (item: DataItem) => {
        const newData = data.map(dataItem => {
            const match =
                (item.hierarchyId && item.hierarchyId === dataItem.hierarchyId) ||
                (item.groupId && item.groupId === dataItem.groupId) ||
                (item.characteristic && item.characteristic.characteristicId === dataItem.characteristic.characteristicId && item.characteristic.value === dataItem.characteristic.value);

            if (match) {
                return { ...dataItem, selected: !dataItem.selected };
            }

            return dataItem;
        });
        setData(newData);
    };

    const renderItemForType = (item: DataItem) => {
        if (item.hierarchyId) {
            return (<HierarchyName id={item.hierarchyId} />);
        } else if (item.groupId) {
            return (<GroupName id={item.groupId} />);
        } else if (item.characteristic) {
            return (<CharacteristicName id={item.characteristic.characteristicId} valueId={item.characteristic.value} />);
        } else {
            return undefined;
        }
    };



    return (
        <HappyLoading loading={loading}>

            <Stack gap={1}>


                <Stack alignItems={'center'} justifyContent={'center'} direction={'row'} gap={1} >
                    <Box flexGrow={1}>
                        <Typography variant='h2'>
                            {$translate.instant('ENPS_POSITIONING')}
                        </Typography>
                    </Box>

                    <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'flex-end'}>
                        <Typography variant='body3'>
                            {$translate.instant('ENGAGEMENT_POSITIONING_BOX_GROUP_BY_INSTRUCTIONS')}
                        </Typography>
                        <FormControl>
                            <InputLabel id='positioning-by-label'>{$translate.instant('SHOW_BY')}</InputLabel>
                            <Select
                                labelId='positioning-by-label'
                                value={positioningBy}
                                label='Show By'
                                onChange={event => {
                                    setPositioningBy(event.target.value);
                                }}
                            >
                                {positioningByOptions.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>

                {!noTypes && (

                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Stack gap={1} alignItems={'flex-start'} justifyContent={'center'}>
                                <Typography variant='body3'>
                                    {$translate.instant('ENGAGEMENT_POSITIONING_FILTER')}
                                </Typography>


                                <Typography variant='body3'>
                                    <Link onClick={selectAll}>
                                        {allSelected ? $translate.instant('UNSELECT_ALL') : $translate.instant('SELECT_ALL')}
                                    </Link>
                                </Typography>

                                <Box>
                                    <FormGroup>
                                        {data.map(item => (
                                            <FormControlLabel
                                                key={item.hierarchyId || item.groupId || item.characteristic?.characteristicId}
                                                control={<Checkbox checked={item.selected} onClick={() => {
                                                    handleSelect(item);
                                                }}/>}
                                                label={renderItemForType(item)}
                                            />
                                        ))}
                                    </FormGroup>
                                </Box>
                            </Stack>


                        </Grid>
                        <Grid item xs={9}>
                            <Stack gap={1} direction={'column'} alignItems={'center'} justifyContent={'center'}>

                                <Box width={'100%'}>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={bubbleChart}></HighchartsReact>
                                </Box>

                                <Typography variant='body3'>
                                    {$translate.instant('ENGAGEMENT_POSITIONING_BOX_INSTRUCTIONS')}
                                </Typography>

                                {noDataItems.length > 0 && (
                                    <Typography variant='body3' fontWeight={'bold'}>
                                        {$translate.instant('ENGAGEMENT_POSITIONING_BOX_NO_DATA_INSTRUCTIONS', {items: noDataItems.map(item => item.name).join(', ')})}
                                    </Typography>
                                )}




                            </Stack>
                        </Grid>
                    </Grid>


                )}
                {noTypes && (
                    <EmptyState
                        image='images/analyze_no_data.png'
                        small
                        message={$translate.instant('ACTIVATION_PACE_NO_SEGMENTS', {})}
                        submessage={$translate.instant('ACTIVATION_PACE_NO_SEGMENTS_SUBMESSAGE', {})}
                    />

                )}


            </Stack>


        </HappyLoading>
    );

};

export default HiPositioningBox;
