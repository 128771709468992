import React, { useState, useRef } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import { Box, Button, Stack } from '@mui/material';
import { angularize, getService } from 'reactInAngular';
import HfDropFileArea from '../hf-drop-file-area/hfDropFileArea';
import angular from 'angular';
import 'react-image-crop/dist/ReactCrop.css';

interface HFImageUploadProps {
    currentImage?: string;
    defaultImage?: string;
    onSelect?: (image: File | null) => void;
    small?: boolean;
}

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    );
}

const HFImageUpload: React.FC<HFImageUploadProps> = ({
    onSelect,
    small = false
}: HFImageUploadProps) => {
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string>('');
    const [isEditing, setIsEditing] = useState(true);
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');

    const imgRef = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const aspect = 1024/720;

    const handleDone = async () => {
        if (!completedCrop || !imgRef.current) { return; }

        try {
            const image = imgRef.current;
            
            // Calcular escala relativa a la imagen original
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;

            // Crear un canvas temporal para el crop
            const canvas = document.createElement('canvas');
            canvas.width = 1024;
            canvas.height = 720;

            const ctx = canvas.getContext('2d');
            if (!ctx) {
                throw new Error('No 2d context');
            }

            // Asegurarse de que las dimensiones son válidas
            const sourceX = Math.max(0, completedCrop.x * scaleX);
            const sourceY = Math.max(0, completedCrop.y * scaleY);
            const sourceWidth = Math.min(completedCrop.width * scaleX, image.naturalWidth - sourceX);
            const sourceHeight = Math.min(completedCrop.height * scaleY, image.naturalHeight - sourceY);

            // Dibujar la imagen recortada
            ctx.drawImage(
                image,
                sourceX,
                sourceY,
                sourceWidth,
                sourceHeight,
                0,
                0,
                canvas.width,
                canvas.height
            );

            // Convertir el canvas a blob
            const blob = await new Promise<Blob>((resolve, reject) => {
                canvas.toBlob(
                    (blob) => {
                        if (!blob) {
                            reject(new Error('Failed to create blob'));
                            return;
                        }
                        resolve(blob);
                    },
                    'image/png',
                    1
                );
            });

            // Crear el archivo final
            const croppedFile = new File([blob], uploadedFile?.name || 'cropped.png', {
                type: 'image/png',
                lastModified: Date.now(),
            });

            setUploadedFile(croppedFile);
            URL.revokeObjectURL(previewUrl); // Limpiar URL anterior
            const newPreviewUrl = URL.createObjectURL(blob);
            setPreviewUrl(newPreviewUrl);
            setIsEditing(false);
            if (onSelect) {
                onSelect(croppedFile);
            }

        } catch (error) {
            console.error('Error cropping image:', error);
            ErrorSvrc.showErrorModal(error);
        }
    };

    const handleUpload = async (file: File | File[]) => {
        try {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl); // Limpiar URL anterior
            }

            let fileToUpload: File;
            if (Array.isArray(file)) {
                fileToUpload = file[0];
            } else {
                fileToUpload = file;
            }

            setUploadedFile(fileToUpload);
            setPreviewUrl(URL.createObjectURL(fileToUpload));
            setIsEditing(true);
            setCrop(undefined);
            setCompletedCrop(undefined);
        } catch (error) {
            console.error('Error processing image:', error);
            ErrorSvrc.showErrorModal(error);
        }
    };

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, aspect));
    };

    return (
        <Stack spacing={2}>
            {uploadedFile && (
                <Stack justifyContent='center' alignItems='center'>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        {isEditing ? (
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={aspect}
                            >
                                <img
                                    ref={imgRef}
                                    src={previewUrl}
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: small ? '200px' : '300px',
                                        objectFit: 'cover',
                                        width: 'auto',
                                        height: 'auto'
                                    }}
                                    onLoad={onImageLoad}
                                    alt='Crop preview'
                                />
                            </ReactCrop>
                        ) : (
                            <img
                                src={previewUrl}
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: small ? '200px' : '200px',
                                    objectFit: 'cover',
                                    width: 'auto',
                                    height: 'auto'
                                }}
                                alt='Final preview'
                            />
                        )}
                    </Box>
                    <Stack direction='row' spacing={2} mt={2}>
                        {isEditing ? (
                            <Button variant='contained' color='primary' onClick={handleDone}>
                                {$translate.instant('DONE')}
                            </Button>
                        ) : (
                            <Button variant='outlined' color='primary' onClick={() => setIsEditing(true)}>
                                {$translate.instant('EDIT')}
                            </Button>
                        )}
                        <Button variant='outlined' color='error' onClick={() => {
                            setUploadedFile(null);
                            if (onSelect) {
                                onSelect(null);
                            }
                            setPreviewUrl('');
                            setIsEditing(true);
                        }}>
                            {$translate.instant('CLEAR')}
                        </Button>
                    </Stack>
                </Stack>
            )}

            {!uploadedFile && (
                <HfDropFileArea
                    onFileUpload={handleUpload}
                    multiple={false}
                    instructions='Drag and drop an image here'
                    subInstructions='Or click to upload'
                    acceptFileTypes={{'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.webp']}}
                    sx={{
                        width: '100% !important',
                    }}
                />
            )}
        </Stack>
    );
};

/* This is our trick for expose these components on angular */
angularize(HFImageUpload, 'hfImageUpload', angular.module('happyForceApp'), {
    currentImage: '<',
    defaultImage: '<',
    onSelect: '<',
    small: '<'
});

export default HFImageUpload;
