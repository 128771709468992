import React, { useMemo } from 'react';
import { getService } from 'reactInAngular';
import HfFilterMenu from '../../../../shared/new-components/hf-filter-menu/hfFilterMenu';
import {
    FilterSelected,
    GroupFiltersConfig,
    HierarchyFiltersConfig,
    OptionFilter,
    OptionFiltersConfig,
    SegmentFiltersConfig,
    DateFiltersConfig,
    NumericRangeFiltersConfig
} from '../../../../shared/new-components/hf-filter-menu/types';

interface FeedbackFiltersProps {
    onFiltersChange: (filters: FilterSelected) => void;
    filters: FilterSelected;
}

const FeedbackFilters: React.FC<FeedbackFiltersProps> = ({
    onFiltersChange,
    filters,
}) => {
    const $translate = getService('$translate');

    const hierarchyFiltersConfig: HierarchyFiltersConfig = useMemo(() => ({
        id: 'HIERARCHY',
        type: 'hierarchyIds',
        mode: 'multi',
        title: $translate.instant('AREA'),
        nonRemovable: false,
    }), []);

    const groupFiltersConfig: GroupFiltersConfig = useMemo(() => ({
        id: 'GROUP',
        type: 'groupIds',
        mode: 'multi',
        title: $translate.instant('GROUPS'),
        nonRemovable: false,
        groups: null,
    }), []);

    const segmentFiltersConfig: SegmentFiltersConfig = useMemo(() => ({
        id: 'SEGMENT',
        type: 'segmentIds',
        mode: 'multi',
        title: $translate.instant('SEGMENTED'),
        nonRemovable: false,
        segments: null
    }), []);

    const activityFilters: OptionFilter[] = [
        {
            title: $translate.instant('COMMENTS_SEARCH_ACTIVITY_REPLIED'),
            type: 'hasCompanyReply',
            mode: 'single',
            nonRemovable: false,
            options: [
                { title: $translate.instant('YES'), id: 'YES' },
                { title: $translate.instant('NO'), id: 'NO' },
            ]
        },
        {
            title: $translate.instant('COMMENTS_SEARCH_ACTIVITY_WITH_REPLIES'),
            type: 'hasEmployeeReplies',
            mode: 'single',
            nonRemovable: false,
            options: [
                { title: $translate.instant('YES'), id: 'YES' },
                { title: $translate.instant('NO'), id: 'NO' },
            ]
        },
        {
            title: $translate.instant('COMMENTS_SEARCH_ACTIVITY_WITH_CONVERSATION'),
            type: 'hasConversation',
            mode: 'single',
            nonRemovable: false,
            options: [
                { title: $translate.instant('YES'), id: 'YES' },
                { title: $translate.instant('NO'), id: 'NO' },
            ]
        },
        {
            title: $translate.instant('COMMENTS_SEARCH_ACTIVITY_PRIVATE'),
            type: 'isPrivate',
            mode: 'single',
            nonRemovable: false,
            options: [
                { title: $translate.instant('YES'), id: 'YES' },
                { title: $translate.instant('NO'), id: 'NO' },
            ]
        },
        {
            title: $translate.instant('COMMENTS_SEARCH_ACTIVITY_SIGNED'),
            type: 'isSigned',
            mode: 'single',
            nonRemovable: false,
            options: [
                { title: $translate.instant('YES'), id: 'YES' },
                { title: $translate.instant('NO'), id: 'NO' },
            ]
        }
    ];

    const feedbackTypeFilters: OptionFilter = {
        title: $translate.instant('COMMENTS_FEEDBACK_TYPE'),
        type: 'feedbackTypes',
        mode: 'multi',
        nonRemovable: false,
        options: [
            {
                title: $translate.instant('FEEDBACK_TYPE_RESULT_INFORMATION'),
                id: 'INFORMATION'
            },
            {
                title: $translate.instant('FEEDBACK_TYPE_RESULT_CONGRATULATION'),
                id: 'CONGRATULATION'
            },
            {
                title: $translate.instant('FEEDBACK_TYPE_RESULT_SUGGESTION'),
                id: 'SUGGESTION'
            },
            {
                title: $translate.instant('FEEDBACK_TYPE_RESULT_CRITICISM'),
                id: 'CRITICISM'
            }
        ]
    };

    const dateFiltersConfig: DateFiltersConfig = {
        type: 'date',
        filters: [
            {
                title: $translate.instant('FROM'),
                type: 'from',
                nonRemovable: false
            },
            {
                title: $translate.instant('TO'),
                type: 'to',
                nonRemovable: false
            }
        ]
    };

    const positivityFiltersConfig: NumericRangeFiltersConfig = {
        type: 'numeric',
        title: $translate.instant('COMMENTS_POSITIVENESS_RANGE'),
        filters: [
            {
                title: $translate.instant('POSITIVITY_FROM'),
                type: 'positivenessFrom',
                nonRemovable: false,
                min: 1,
                max: 10,
                step: 1
            },
            {
                title: $translate.instant('POSITIVITY_TO'),
                type: 'positivenessTo',
                nonRemovable: false,
                min: 1,
                max: 10,
                step: 1
            }
        ]
    };

    const optionFiltersConfig: OptionFiltersConfig = {
        filters: [...activityFilters, feedbackTypeFilters]
    };

    return (
        <HfFilterMenu
            chipSize='small'
            mode='tagging'
            hierarchyFiltersConfig={hierarchyFiltersConfig}
            groupFiltersConfig={groupFiltersConfig}
            segmentFiltersConfig={segmentFiltersConfig}
            optionFiltersConfig={optionFiltersConfig}
            dateFiltersConfig={dateFiltersConfig}
            numericRangeFiltersConfig={positivityFiltersConfig}
            selectedFilters={filters}
            updateFilters={onFiltersChange}
        />
    );
};

export default FeedbackFilters;
