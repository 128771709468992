import * as React from 'react';
import { getService } from 'reactInAngular';
import HfFilterMenu from '../../../../shared/new-components/hf-filter-menu/hfFilterMenu';
import {
    FilterSelected,
    OptionFilter,
    OptionFiltersConfig,
    NumericRangeFiltersConfig
} from '../../../../shared/new-components/hf-filter-menu/types';

interface EngagementFeedbackFiltersProps {
    onFiltersChange: (filters: FilterSelected) => void;
    filters: FilterSelected;
    mode?: 'ENPS' | 'SCORE' | 'HI' | 'ALL';
}

const EngagementFeedbackFilters: React.FC<EngagementFeedbackFiltersProps> = ({
    onFiltersChange,
    filters,
    mode
}) => {
    const $translate = getService('$translate');

    const activityFilters: OptionFilter[] = [
        {
            title: $translate.instant('COMMENTS_SEARCH_ACTIVITY_WITH_CONVERSATION'),
            type: 'hasConversation',
            mode: 'single',
            nonRemovable: false,
            options: [
                { title: $translate.instant('YES'), id: 'YES' },
                { title: $translate.instant('NO'), id: 'NO' },
            ]
        }
    ];

    if (mode === 'ENPS') {
        activityFilters.push({
            title: $translate.instant('ENPS_CLASSIFICATION'),
            type: 'enpsClassification',
            mode: 'multi',
            nonRemovable: false,
            options: [
                { title: $translate.instant('ENPS_DETRACTORS'), id: 'DETRACTORS' },
                { title: $translate.instant('ENPS_PASSIVES'), id: 'PASSIVES' },
                { title: $translate.instant('ENPS_PROMOTERS'), id: 'PROMOTERS' }
            ]
        });
    }

    const positivityFiltersConfig: NumericRangeFiltersConfig = {
        type: 'numeric',
        title: $translate.instant('COMMENTS_POSITIVENESS_RANGE'),
        filters: [
            {
                title: $translate.instant('POSITIVITY_FROM'),
                type: 'positivenessFrom',
                nonRemovable: false,
                min: 1,
                max: 10,
                step: 1
            },
            {
                title: $translate.instant('POSITIVITY_TO'),
                type: 'positivenessTo',
                nonRemovable: false,
                min: 1,
                max: 10,
                step: 1
            }
        ]
    };

    const optionFiltersConfig: OptionFiltersConfig = {
        filters: activityFilters
    };

    return (
        <HfFilterMenu
            chipSize='small'
            mode='tagging'
            optionFiltersConfig={optionFiltersConfig}
            numericRangeFiltersConfig={positivityFiltersConfig}
            selectedFilters={filters}
            updateFilters={onFiltersChange}
        />
    );
};

export default EngagementFeedbackFilters;
