import * as React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
    Stack,
} from '@mui/material';
import { getService } from 'reactInAngular';

interface FeedbackReportTypeModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (type: string) => void;
}

const FeedbackReportTypeModal: React.FC<FeedbackReportTypeModalProps> = ({ open, onClose, onConfirm }) => {
    const [reportType, setReportType] = React.useState('LIST');
    const $translate = getService('$translate');

    const handleConfirm = () => {
        onConfirm(reportType);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth='sm'
            fullWidth
        >
            <DialogTitle>
                <Typography variant={'h4'}>
                    {$translate.instant('FEEDBACK_REPORT_TYPE_MODAL')}
                </Typography>

                <Typography variant='body3' color='textSecondary' sx={{ mb: 2 }}>
                    {$translate.instant('FEEDBACK_REPORT_TYPE_MODAL_DESCRIPTION')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <FormControl component='fieldset' fullWidth>
                    <RadioGroup
                        value={reportType}
                        onChange={(e) => setReportType(e.target.value)}
                    >
                        <Stack gap={1} mb={2}>
                            <FormControlLabel
                                value='LIST'
                                control={<Radio />}
                                label={$translate.instant('FEEDBACK_REPORT_LIST')}
                            />
                            <Typography
                                variant='body3'
                                color='textSecondary'

                            >
                                {$translate.instant('FEEDBACK_REPORT_LIST_DESCRIPTION')}
                            </Typography>
                        </Stack>

                        <Stack gap={1}>
                            <FormControlLabel
                                value='FIRST_REPLY_TIMES'
                                control={<Radio />}
                                label={$translate.instant('FEEDBACK_REPORT_REPLY_TIMES')}
                            />
                            <Typography
                                variant='body3'
                                color='textSecondary'
                            >
                                {$translate.instant('FEEDBACK_REPORT_REPLY_TIMES_DESCRIPTION')}
                            </Typography>
                        </Stack>
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color='primary'>
                    {$translate.instant('CANCEL')}
                </Button>
                <Button onClick={handleConfirm} color='primary' variant='contained'>
                    {$translate.instant('CONFIRM')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// hook to manage the modal state and functionality
export const useFeedbackReportTypeModal = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [pendingCallback, setPendingCallback] = React.useState<((type: string) => void) | null>(null);
    const Reports = getService('Reports');
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');
    const toaster = getService('toaster');
    const Analytics = getService('Analytics');

    const showModal = (params: any, callback: () => void) => {
        setIsOpen(true);
        setPendingCallback(() => (type: string) => {
            const finalParams = { ...params, reportType: type };
            Reports.comments(finalParams,
                () => {
                    toaster.pop('success', null, $translate.instant('SURVEY_EXPORT_SENT'));
                    callback();
                    Analytics.trackEvent('comments', 'excel_report', 'success');
                },
                (error: any) => {
                    ErrorSvrc.showErrorModal(error);
                    Analytics.trackEvent('comments', 'excel_report', 'false');
                }
            );
        });
    };

    const handleClose = () => {
        setIsOpen(false);
        setPendingCallback(null);
    };

    const handleConfirm = (type: string) => {
        if (pendingCallback) {
            pendingCallback(type);
        }
        handleClose();
    };

    const ModalComponent = () => (
        <FeedbackReportTypeModal
            open={isOpen}
            onClose={handleClose}
            onConfirm={handleConfirm}
        />
    );

    return {
        showCommentsReportModal: showModal,
        ModalComponent
    };
};

export default FeedbackReportTypeModal;
