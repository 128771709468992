import React, { useEffect, useState } from 'react';
import { angularize, getService } from 'reactInAngular';
import { Menu, Box, Button, Typography, Chip, Stack } from '@mui/material';
import HierarchyNestedMenuItem from './components/HierarchyNestedMenuItem';
import GroupNestedMenuItem from './components/GroupNestedMenuItem';
import SegmentNestedMenuItem from './components/SegmentNestedMenuItem';
import OptionNestedMenuItem from './components/OptionNestedMenuItem';
import DateNestedMenuItem from './components/DateNestedMenuItem';
import NumberRangeMenuItem from './components/NumberRangeMenuItem';
import angular from 'angular';
import FilterIcon from '@mui/icons-material/FilterAlt';
import {
    HierarchyFiltersConfig, GroupFiltersConfig,
    SegmentFiltersConfig, DateFiltersConfig,
    OptionFiltersConfig, Option,
    PillFilter, SegmentSelected, FilterSelected,
    NumericRangeFiltersConfig
} from './types';
import Dates from '../../../utilities/date.utilities';
import {getHierarchyById} from '../../services/migrated/hierarchy.service';

interface HFFilterMenuProps {
    hierarchyFiltersConfig?: HierarchyFiltersConfig;
    groupFiltersConfig?: GroupFiltersConfig;
    segmentFiltersConfig?: SegmentFiltersConfig;
    dateFiltersConfig?: DateFiltersConfig;
    optionFiltersConfig?: OptionFiltersConfig;
    tagColor?: string;
    selectedFilters: FilterSelected;
    updateFilters: (filters: FilterSelected) => void;
    mode?: 'default' | 'tagging';
    chipSize?: 'small' | 'medium';
    numericRangeFiltersConfig?: NumericRangeFiltersConfig;
}

const HfFilterMenu = ({ hierarchyFiltersConfig, groupFiltersConfig,
    segmentFiltersConfig, dateFiltersConfig, optionFiltersConfig,
    tagColor, selectedFilters, updateFilters, mode = 'default', chipSize = 'small',
    numericRangeFiltersConfig }: HFFilterMenuProps) => {
    const [tagFilters, setTagFilters] = useState<PillFilter[]>([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeFilter, setActiveFilter] = useState<string | null>(null);
    const $translate = getService('$translate');
    const Languages = getService('Languages');
    const Groups = getService('Groups');
    const Characteristics = getService('Characteristics');
    const Settings = getService('Settings');
    const open = Boolean(anchorEl);

    const handleAddFilter = (type: string, options: any[] | any) => {

        const newFilters = { ...selectedFilters };

        if (!options || options.length === 0) {
            delete newFilters[type];
        } else {
            newFilters[type] = options;
        }

        updateFilters(newFilters);
    };

    const handleAddRangeFilter = (fromType: string, toType: string, values: [number, number]) => {
        const newFilters = { ...selectedFilters };
        newFilters[fromType] = values[0];
        newFilters[toType] = values[1];
        updateFilters(newFilters);
    };

    /*
        This hook is responsible for updating the tags that are displayed on the filter menu.
     */
    useEffect(() => {



        if (selectedFilters) {


            const newTags = [];

            // 1. Check if the selected filter is a hierarchy filter

            if (hierarchyFiltersConfig && selectedFilters[hierarchyFiltersConfig.type]) {

                // first' remove all tags from the given type

                const selectedHierarchyFilters = selectedFilters[hierarchyFiltersConfig.type] as string[];
                selectedHierarchyFilters.forEach(hierarchyId => {


                    getHierarchyById(hierarchyId, (error, hierarchy) => {
                        if (hierarchy) {
                            newTags.push({
                                nonRemovable: hierarchyFiltersConfig.nonRemovable,
                                option: hierarchyFiltersConfig.title,
                                name: hierarchy.name,
                                type: hierarchyFiltersConfig.type,
                                id: hierarchy.id
                            });
                        }
                    });
                });
            }

            if (groupFiltersConfig && selectedFilters[groupFiltersConfig.type]) {
                // we have a group filter here.

                const selectedGroupFilters = selectedFilters[groupFiltersConfig.type] as string[];
                selectedGroupFilters.forEach(groupId => {
                    Groups.getGroup(groupId, (error, group) => {
                        if (error) {
                            console.error(error);
                        }

                        newTags.push({
                            nonRemovable: groupFiltersConfig.nonRemovable,
                            option: groupFiltersConfig.title,
                            name: group.name,
                            type: groupFiltersConfig.type,
                            id: group.id
                        });

                    });
                });



            }

            if (segmentFiltersConfig && selectedFilters[segmentFiltersConfig.type]) {
                // we have a segment filter here.

                const selectedSegmentFilters = selectedFilters[segmentFiltersConfig.type] as SegmentSelected[];
                selectedSegmentFilters.forEach(selectedSegment => {

                    // retrieve the name of the segment and value from the API:
                    const characteristicName = Characteristics.getCharacteristicNameById(selectedSegment.id);
                    selectedSegment.values.forEach(valueId => {

                        const valueName = Characteristics.getCharacteristicValueName(selectedSegment.id, valueId);

                        newTags.push({
                            nonRemovable: segmentFiltersConfig.nonRemovable,
                            option: Languages.getTranslationFromUserLanguage(characteristicName),
                            name: Languages.getTranslationFromUserLanguage(valueName),
                            type: segmentFiltersConfig.type,
                            id: selectedSegment.id,
                            subId: valueId
                        });
                    });
                });


            }

            if (dateFiltersConfig) {


                // check if we have a date filter on the selected filters
                dateFiltersConfig.filters.forEach(dateFilter => {

                    if (selectedFilters[dateFilter.type]) {
                        const date = selectedFilters[dateFilter.type] as Date;
                        newTags.push({
                            nonRemovable: dateFilter.nonRemovable,
                            option: dateFilter.title,
                            name: Dates.toAPIFormat(date, Settings.defaultDateFormat),
                            type: dateFilter.type,
                        });
                    }
                });
            }

            if (optionFiltersConfig) {
                // check if we have an option filter on the selected filters
                optionFiltersConfig.filters.forEach(optionFilter => {
                    if (selectedFilters[optionFilter.type]) {
                        const selectedOptions = selectedFilters[optionFilter.type] as string[];
                        selectedOptions.forEach(optionId => {
                            const option = findOptionById(optionFilter.options, optionId);
                            if (option) {
                                newTags.push({
                                    nonRemovable: optionFilter.nonRemovable,
                                    option: optionFilter.title,
                                    name: option.title,
                                    type: optionFilter.type,
                                    id: option.id
                                });
                            } else {
                                console.error('Option not found', optionId);
                            }
                        });
                    }
                });

            }

            setTagFilters(newTags);

        }


    }, [selectedFilters]);

    const findOptionById = (options: Option[], id: string): Option | undefined => {
        for (const option of options) {
            if (option.id === id) {
                return option;  // if the ID matches, return the option.
            }

            // if there are children, perform a recursive search.
            if (option.children) {
                const found = findOptionById(option.children, id);
                if (found) {
                    return found;  // if found in children, return it.
                }
            }
        }
        return undefined;  // if nothing is found, return undefined.
    };

    const handleChipClick = (event: React.MouseEvent<HTMLDivElement>, filterType: string) => {
        setAnchorEl(event.currentTarget);
        setActiveFilter(filterType);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setActiveFilter(null);
    };

    const handleRemove = (tag: PillFilter) => {
        if (selectedFilters && selectedFilters[tag.type]) {
            const currentFilters = selectedFilters[tag.type];

            if (currentFilters && Array.isArray(currentFilters)) {
                if (currentFilters.length > 0 && typeof currentFilters[0] === 'object' && 'values' in currentFilters[0]) {
                    // segment filter
                    const updatedSegments = (currentFilters as SegmentSelected[]).map(segment => {
                        if (segment.id === tag.id) {
                            const updatedValues = segment.values.filter(value => value !== tag.subId);
                            return updatedValues.length > 0 ? { ...segment, values: updatedValues } : null;
                        }
                        return segment;
                    }).filter(Boolean) as SegmentSelected[];

                    const updatedFilters = { ...selectedFilters };
                    updatedFilters[tag.type] = updatedSegments;
                    updateFilters(updatedFilters);
                } else {
                    // handle string[] arrays (boolean[] is not used in tags)
                    const newFilters = (currentFilters as string[]).filter(filter => filter !== tag.id);
                    const updatedFilters = { ...selectedFilters };
                    updatedFilters[tag.type] = newFilters;
                    updateFilters(updatedFilters);
                }
            } else {
                const updatedFilters = { ...selectedFilters };
                delete updatedFilters[tag.type];
                updateFilters(updatedFilters);
            }
        }
    };

    const renderTags = () => {
        if (mode === 'tagging') {
            return null;
        }

        if (!tagFilters || !tagFilters.length) {
            return null;
        }

        return (
            <Box sx={(theme) => ({
                display: 'flex', alignContent: 'center', gap: theme.spacing(1),
                flexDirection: 'row', flexWrap: 'wrap', overflow: 'hidden'
            })}>
                {
                    tagFilters.map((tag, tagIndex) => (
                        <Chip
                            key={tagIndex}
                            size={chipSize}
                            label={
                                <Box sx={{ display: 'flex', gap: 0.5 }}>
                                    <Typography variant={chipSize === 'small' ? 'caption' : 'body2'} fontWeight='bold'>{tag.option}: </Typography>
                                    <Typography variant={chipSize === 'small' ? 'caption' : 'body2'}>{tag.name}</Typography>
                                </Box>
                            }
                            onDelete={!tag.nonRemovable ? () => handleRemove(tag) : undefined}
                            color={tagColor === 'success' ? 'success' :
                                tagColor === 'error' ? 'error' :
                                    tagColor === 'warning' ? 'warning' :
                                        tagColor === 'info' ? 'info' : 'default'}
                        />
                    ))
                }
            </Box>
        );
    };

    const renderFilterMenu = () => {
        if (mode === 'default') {
            return (
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {hierarchyFiltersConfig &&
                        <HierarchyNestedMenuItem
                            open={open}
                            type={hierarchyFiltersConfig.type}
                            filters={(selectedFilters && selectedFilters[hierarchyFiltersConfig.type] as string[]) ?? []}
                            onAddFilter={handleAddFilter}
                            mode={hierarchyFiltersConfig.mode as 'single' | 'multi' | 'grouped'}
                            title={hierarchyFiltersConfig.title}
                            nonRemovable={hierarchyFiltersConfig.nonRemovable}
                            hierarchies={hierarchyFiltersConfig.hierarchies} />}
                    {groupFiltersConfig &&
                        <GroupNestedMenuItem
                            open={open}
                            type={groupFiltersConfig.type}
                            filters={(selectedFilters && selectedFilters[groupFiltersConfig.type] as string[]) ?? []}
                            onAddFilter={handleAddFilter}
                            mode={groupFiltersConfig.mode}
                            title={groupFiltersConfig.title}
                            nonRemovable={groupFiltersConfig.nonRemovable}
                            groups={groupFiltersConfig.groups} />}
                    {segmentFiltersConfig &&
                        <SegmentNestedMenuItem
                            open={open}
                            type={segmentFiltersConfig.type}
                            segments={segmentFiltersConfig.segments}
                            filters={
                                (selectedFilters && selectedFilters[segmentFiltersConfig.type] as SegmentSelected[]) ?? []
                            }
                            onAddFilter={handleAddFilter}
                            title={segmentFiltersConfig.title}
                            mode={segmentFiltersConfig.mode as 'single' | 'multi' | 'grouped'}
                            nonRemovable={segmentFiltersConfig.nonRemovable} />}
                    {optionFiltersConfig &&
                        <>{optionFiltersConfig?.filters?.map((optionFilter, index) => (
                            <OptionNestedMenuItem
                                key={index}
                                open={open}
                                options={optionFilter.options}
                                filters={selectedFilters[optionFilter.type] as string[] | boolean[]}
                                onAddFilter={handleAddFilter}
                                title={optionFilter.title}
                                type={optionFilter.type}
                                mode={optionFilter.mode}
                                nonRemovable={optionFilter.nonRemovable} />
                        ))}</>}
                    {dateFiltersConfig &&
                        <>{dateFiltersConfig?.filters.map((dateFilter, index) => (
                            <DateNestedMenuItem
                                key={index}
                                open={open}
                                filters={selectedFilters[dateFilter.type] as Date}
                                onAddFilter={handleAddFilter}
                                title={dateFilter.title}
                                type={dateFilter.type}
                                nonRemovable={dateFilter.nonRemovable} />
                        ))}</>}
                    {numericRangeFiltersConfig && (
                        <NumberRangeMenuItem
                            open={open}
                            fromValue={selectedFilters.positivenessFrom as number}
                            toValue={selectedFilters.positivenessTo as number}
                            onAddFilter={handleAddRangeFilter}
                            title={numericRangeFiltersConfig.title}
                            fromType='positivenessFrom'
                            toType='positivenessTo'
                            nonRemovable={false}
                            min={1}
                            max={10}
                            step={1}
                        />
                    )}
                </Menu>
            );
        } else {


            // tagging mode - show only the active filter's menu
            return (
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {activeFilter === hierarchyFiltersConfig?.type && hierarchyFiltersConfig &&
                        <HierarchyNestedMenuItem
                            open={open}
                            type={hierarchyFiltersConfig.type}
                            filters={(selectedFilters && selectedFilters[hierarchyFiltersConfig.type] as string[]) ?? []}
                            onAddFilter={handleAddFilter}
                            mode={hierarchyFiltersConfig.mode as 'single' | 'multi' | 'grouped'}
                            title={hierarchyFiltersConfig.title}
                            nonRemovable={hierarchyFiltersConfig.nonRemovable}
                            hierarchies={hierarchyFiltersConfig.hierarchies} />}
                    {activeFilter === groupFiltersConfig?.type && groupFiltersConfig &&
                        <GroupNestedMenuItem
                            open={open}
                            type={groupFiltersConfig.type}
                            filters={(selectedFilters && selectedFilters[groupFiltersConfig.type] as string[]) ?? []}
                            onAddFilter={handleAddFilter}
                            mode={groupFiltersConfig.mode}
                            title={groupFiltersConfig.title}
                            nonRemovable={groupFiltersConfig.nonRemovable}
                            groups={groupFiltersConfig.groups} />}
                    {activeFilter === segmentFiltersConfig?.type && segmentFiltersConfig &&
                        <SegmentNestedMenuItem
                            open={open}
                            type={segmentFiltersConfig.type}
                            segments={segmentFiltersConfig.segments}
                            filters={
                                (selectedFilters && selectedFilters[segmentFiltersConfig.type] as SegmentSelected[]) ?? []
                            }
                            onAddFilter={handleAddFilter}
                            title={segmentFiltersConfig.title}
                            mode={segmentFiltersConfig.mode as 'single' | 'multi' | 'grouped'}
                            nonRemovable={segmentFiltersConfig.nonRemovable} />}
                    {optionFiltersConfig?.filters?.map((filter, index) => (
                        activeFilter === filter.type && (
                            <OptionNestedMenuItem
                                key={index}
                                open={open}
                                options={filter.options}
                                filters={selectedFilters[filter.type] as string[] | boolean[]}
                                onAddFilter={handleAddFilter}
                                title={filter.title}
                                type={filter.type}
                                mode={filter.mode as 'single' | 'multi' | 'grouped'}
                                nonRemovable={filter.nonRemovable} />
                        )
                    ))}
                    {dateFiltersConfig?.filters?.map((filter, index) => (
                        activeFilter === filter.type && (
                            <DateNestedMenuItem
                                key={index}
                                open={open}
                                filters={selectedFilters[filter.type] as Date}
                                onAddFilter={handleAddFilter}
                                title={filter.title}
                                type={filter.type}
                                nonRemovable={filter.nonRemovable} />
                        )
                    ))}
                    {numericRangeFiltersConfig && activeFilter === 'positiveness' && (
                        <NumberRangeMenuItem
                            open={open}
                            fromValue={selectedFilters.positivenessFrom as number}
                            toValue={selectedFilters.positivenessTo as number}
                            onAddFilter={handleAddRangeFilter}
                            title={numericRangeFiltersConfig.title}
                            fromType='positivenessFrom'
                            toType='positivenessTo'
                            nonRemovable={false}
                            min={1}
                            max={10}
                            step={1}
                        />
                    )}
                </Menu>
            );
        }

    };

    const renderFilterTrigger = () => {
        if (mode === 'default') {
            return (
                <Button
                    variant='text'
                    size={chipSize}
                    style={{ textTransform: 'none' }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    startIcon={<FilterIcon />}>
                    <Typography variant={chipSize === 'small' ? 'caption' : 'body3'} sx={{ textTransform: 'capitalize' }}>
                        {$translate.instant('FILTER')}
                    </Typography>
                </Button>
            );
        }

        // tagging mode - show chips for each filter type with selected values
        return (
            <Box sx={(theme) => ({ display: 'flex', gap: theme.spacing(1), flexWrap:  'wrap' })}>
                {hierarchyFiltersConfig && (
                    <Chip
                        size={chipSize}
                        label={
                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                                <Typography variant={chipSize === 'small' ? 'caption' : 'body3'} fontWeight='bold'>{hierarchyFiltersConfig.title}</Typography>
                                {tagFilters.filter(tag => tag.type === hierarchyFiltersConfig.type).length > 0 && (
                                    <Typography variant={chipSize === 'small' ? 'caption' : 'body3'} sx={{ opacity: 0.7 }}>
                                        ({tagFilters
                                            .filter(tag => tag.type === hierarchyFiltersConfig.type)
                                            .map(tag => tag.name)
                                            .reduce((acc, curr) => acc ? `${acc}, ${curr}` : curr, '')})
                                    </Typography>
                                )}
                            </Box>
                        }
                        onClick={(e) => handleChipClick(e, hierarchyFiltersConfig.type)}
                        onDelete={!hierarchyFiltersConfig.nonRemovable && tagFilters.filter(tag => tag.type === hierarchyFiltersConfig.type).length > 0 ?
                            () => {
                                const updatedFilters = { ...selectedFilters };
                                delete updatedFilters[hierarchyFiltersConfig.type];
                                updateFilters(updatedFilters);
                            } : undefined}
                        variant={tagFilters.filter(tag => tag.type === hierarchyFiltersConfig.type).length > 0 ? 'filled' : 'outlined'}
                        color={'primary'}
                    />
                )}
                {groupFiltersConfig && (
                    <Chip
                        size={chipSize}
                        label={
                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                                <Typography variant={chipSize === 'small' ? 'caption' : 'body3'} fontWeight='bold'>{groupFiltersConfig.title}</Typography>
                                {tagFilters.filter(tag => tag.type === groupFiltersConfig.type).length > 0 && (
                                    <Typography variant={chipSize === 'small' ? 'caption' : 'body3'} sx={{ opacity: 0.7 }}>
                                        ({tagFilters
                                            .filter(tag => tag.type === groupFiltersConfig.type)
                                            .map(tag => tag.name)
                                            .reduce((acc, curr) => acc ? `${acc}, ${curr}` : curr, '')})
                                    </Typography>
                                )}
                            </Box>
                        }
                        onClick={(e) => handleChipClick(e, groupFiltersConfig.type)}
                        onDelete={!groupFiltersConfig.nonRemovable && tagFilters.filter(tag => tag.type === groupFiltersConfig.type).length > 0 ?
                            () => {
                                const updatedFilters = { ...selectedFilters };
                                delete updatedFilters[groupFiltersConfig.type];
                                updateFilters(updatedFilters);
                            } : undefined}
                        variant={tagFilters.filter(tag => tag.type === groupFiltersConfig.type).length > 0 ? 'filled' : 'outlined'}
                        color={'primary'}
                    />
                )}
                {segmentFiltersConfig && (
                    <Chip
                        size={chipSize}
                        label={
                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                                <Typography variant={chipSize === 'small' ? 'caption' : 'body3'} fontWeight='bold'>{segmentFiltersConfig.title}</Typography>
                                {tagFilters.filter(tag => tag.type === segmentFiltersConfig.type).length > 0 && (
                                    <Typography variant={chipSize === 'small' ? 'caption' : 'body3'} sx={{ opacity: 0.7 }}>
                                        ({tagFilters
                                            .filter(tag => tag.type === segmentFiltersConfig.type)
                                            .map(tag => tag.name)
                                            .reduce((acc, curr) => acc ? `${acc}, ${curr}` : curr, '')})
                                    </Typography>
                                )}
                            </Box>
                        }
                        onClick={(e) => handleChipClick(e, segmentFiltersConfig.type)}
                        onDelete={!segmentFiltersConfig.nonRemovable && tagFilters.filter(tag => tag.type === segmentFiltersConfig.type).length > 0 ?
                            () => {
                                const updatedFilters = { ...selectedFilters };
                                delete updatedFilters[segmentFiltersConfig.type];
                                updateFilters(updatedFilters);
                            } : undefined}
                        variant={tagFilters.filter(tag => tag.type === segmentFiltersConfig.type).length > 0 ? 'filled' : 'outlined'}
                        color={'primary'}
                    />
                )}
                {optionFiltersConfig?.filters?.map((filter, index) => (
                    <Chip
                        key={index}
                        size={chipSize}
                        label={
                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                                <Typography variant={chipSize === 'small' ? 'caption' : 'body3'} fontWeight='bold'>{filter.title}</Typography>
                                {tagFilters.filter(tag => tag.type === filter.type).length > 0 && (
                                    <Typography variant={chipSize === 'small' ? 'caption' : 'body3'} sx={{ opacity: 0.7 }}>
                                        ({tagFilters
                                            .filter(tag => tag.type === filter.type)
                                            .map(tag => tag.name)
                                            .reduce((acc, curr) => acc ? `${acc}, ${curr}` : curr, '')})
                                    </Typography>
                                )}
                            </Box>
                        }
                        onClick={(e) => handleChipClick(e, filter.type)}
                        onDelete={!filter.nonRemovable && tagFilters.filter(tag => tag.type === filter.type).length > 0 ?
                            () => {
                                const updatedFilters = { ...selectedFilters };
                                delete updatedFilters[filter.type];
                                updateFilters(updatedFilters);
                            } : undefined}
                        variant={selectedFilters[filter.type] && tagFilters.filter(tag => tag.type === filter.type).length > 0 ? 'filled' : 'outlined'}
                        color={'primary'}
                    />
                ))}
                {dateFiltersConfig?.filters?.map((filter, index) => (
                    <Chip
                        key={index}
                        size={chipSize}
                        label={
                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                                <Typography variant={chipSize === 'small' ? 'caption' : 'body3'} fontWeight='bold'>{filter.title}</Typography>
                                {selectedFilters[filter.type] && (
                                    <Typography variant={chipSize === 'small' ? 'caption' : 'body3'} sx={{ opacity: 0.7 }}>
                                        ({Dates.toAPIFormat(selectedFilters[filter.type] as Date, Settings.defaultDateFormat)})
                                    </Typography>
                                )}
                            </Box>
                        }
                        onClick={(e) => handleChipClick(e, filter.type)}
                        onDelete={!filter.nonRemovable && selectedFilters[filter.type] ?
                            () => {
                                const updatedFilters = { ...selectedFilters };
                                delete updatedFilters[filter.type];
                                updateFilters(updatedFilters);
                            } : undefined}
                        variant={selectedFilters[filter.type] ? 'filled' : 'outlined'}
                        color={'primary'}
                    />
                ))}
                {numericRangeFiltersConfig && (
                    <Chip
                        size={chipSize}
                        variant={selectedFilters.positivenessFrom || selectedFilters.positivenessTo ? 'filled' : 'outlined'}
                        label={
                            <Stack direction='row' spacing={0.5}>
                                <Typography variant={chipSize === 'small' ? 'caption' : 'body3'} fontWeight='bold'>
                                    {numericRangeFiltersConfig.title}
                                </Typography>
                                {(selectedFilters.positivenessFrom || selectedFilters.positivenessTo) && (
                                    <Typography variant={chipSize === 'small' ? 'caption' : 'body3'} sx={{ opacity: 0.7 }}>
                                        ({selectedFilters.positivenessFrom || 1} - {selectedFilters.positivenessTo || 10})
                                    </Typography>
                                )}
                            </Stack>
                        }
                        onClick={(e) => handleChipClick(e, 'positiveness')}
                        onDelete={(selectedFilters.positivenessFrom || selectedFilters.positivenessTo) ?
                            () => {
                                const updatedFilters = { ...selectedFilters };
                                delete updatedFilters.positivenessFrom;
                                delete updatedFilters.positivenessTo;
                                updateFilters(updatedFilters);
                            } : undefined}
                        color={'primary'}
                    />
                )}
            </Box>
        );
    };

    const content = (
        <>
            {renderFilterTrigger()}
            {renderFilterMenu()}
            {renderTags()}
        </>
    );

    return (
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'center' }}>
            {content}
        </Box>
    );
};

angularize(HfFilterMenu, 'hfFilterMenu', angular.module('happyForceApp'), {
    hierarchyFiltersConfig: '<',
    groupFiltersConfig: '<',
    segmentFiltersConfig: '<',
    optionFiltersConfig: '<',
    dateFiltersConfig: '<',
    selectedFilters: '<',
    updateFilters: '<',
    tagColor: '<',
    mode: '<',
    chipSize: '<',
    numericRangeFiltersConfig: '<',
});

export default HfFilterMenu;
