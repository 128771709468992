

function AnnouncementsLifeEventTypeEditorController($scope, $rootScope, Announcements, $translate, Settings, ErrorSvrc, toaster) {
    'ngInject';
    var _this = this;

    _this.savingInprogress = false;

    _this.onContentUpdated = function (content) {
        _this.announcement.message.defaultValue = content;
    };

    _this.onMediaUpdated = function (media) {
        if (!_this.announcement.media) {
            _this.announcement.media = [];
        }

        return _this.announcement.media.push(
            media
        );
    };

    _this.validAnnouncement = function () {
        return _this.announcement.message && _this.announcement.message.replace(/(<([^>]+)>)/gi, '').length > 0;
    };


    _this.save = function () {
        _this.announcement.sectionId =  Settings.announcementSectionsConfig.celebrationSectionId;
        _this.savingInprogress = true;
        Announcements.update(_this.announcement.id, _this.announcement, function (err, announcement) {
            if (err) {
                _this.savingInprogress = false;
                ErrorSvrc.showErrorModal(err);
            } else {
                toaster.pop('success', null, ($translate.instant('ANNOUNCEMENT_UPDATED')));
                $rootScope.$broadcast('announcementUpdated', { id: announcement.id, sectionId:
                    Settings.announcementSectionsConfig.celebrationSectionId, statusChange: false });
                if (_this.onAnnouncementEdited)
                {_this.onAnnouncementEdited(announcement.id);}
            }
            _this.savingInprogress = false;
        });
    };


    _this.cancel = function () {
        if (_this.onExitEdition)
        {_this.onExitEdition();}
    };
}

const hfAnnouncementLifeEventTypeEditor = angular.module('happyForceApp')
    .directive('hfAnnouncementLifeEventTypeEditor', function () {
        return {
            scope: {
                announcement: '<',
                onAnnouncementEdited: '<',
                onExitEdition: '<'
            },
            replace: true,
            template: require('./announcement-lifeevent-type-editor.html'),
            controller: AnnouncementsLifeEventTypeEditorController,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfAnnouncementLifeEventTypeEditor;
