import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {Box, Button, Typography, IconButton, Pagination, Stack} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getService } from 'reactInAngular';
import HfConversation from '../../../new-components/hf-conversation/hfConversation';
import {TitleCloseableDrawer} from '../../../new-components/hf-title-closeable-drawer/hfTiteCloseableDrawer';
import HfUserSmall from '../user-small/hfUserSmall';
import EmptyState from '../empty_state/empty_state';
import Dates from '../../../../utilities/date.utilities';
import {APISettings} from '../../../services/migrated/axiosWrapper';
import {HfBannerReact} from '../../../new-components/hf-banner/hfBanner';
import PersonEngagementContext from '../../../new-components/hf-person-engagement-context/hfPersonEngagementContext';




interface PersonEngagementStatusContextModalProps {
    from: Date;
    to: Date;
    employeeToken: string;
    showDrawer: boolean;
    onClose: () => void;
}

const PersonEngagementStatusContextModal = ({ from, to, employeeToken, showDrawer, onClose } : PersonEngagementStatusContextModalProps) => {
    const $translate = getService('$translate');
    const ErrorSvrc = getService('ErrorSvrc');
    const AuthSrvc = getService('AuthSrvc');


    const Conversation = getService('Conversation');
    const CompanySvrc = getService('CompanySvrc');
    const [statusTo, setStatusTo] = useState(moment(to).endOf('month').toDate());
    const [statusFrom, setStatusFrom] = useState(moment(from).startOf('month').toDate());
    const [loadingConversations, setLoadingConversations] = useState(false);
    const [canStartConversation, setCanStartConversation] = useState(false);
    const [conversations, setConversations] = useState([]);
    const [totalConversations, setTotalConversations] = useState(0);
    const [currentConversationsPage, setCurrentConversationsPage] = useState(1);
    const [displayConversation, setDisplayConversation] = useState(false);
    const [conversationId, setConversationId] = useState(null);
    const [conversationDate, setConversationDate] = useState(null);

    const loadPersonConversations = () => {
        setLoadingConversations(true);
        const params = {
            personEncryptedId: employeeToken,
            fromConversation: (currentConversationsPage - 1) * 5,
            toConversation: currentConversationsPage * 5,
        };

        Conversation.getPreview(params, (err, data) => {
            if (err) {
                setLoadingConversations(false);
                ErrorSvrc.showErrorModal(err);
                return;
            }
            setTotalConversations(data.total);
            setConversations(data.results || []);
            setLoadingConversations(false);
        });
    };


    const newConversation = () => {
        setDisplayConversation(true);
    };

    const closeConversation = () => {
        setDisplayConversation(false);
        setConversationId(null);
        setConversationDate(null);
        setConversations([]);
        setTotalConversations(0);
        setCurrentConversationsPage(1);
        setStatusTo(moment(to).endOf('month').toDate());
    };

    const handleConversationStarted = (err, id) => {
        setConversationId(id);
    };

    const viewConversation = (id, date) => {
        setDisplayConversation(true);
        setConversationId(id);
        setConversationDate(date);
        setStatusTo(moment(date).endOf('month').toDate());
    };



    useEffect(() => {
        if (canStartConversation) {
            loadPersonConversations();
        }
    }, [canStartConversation]);


    useEffect(() => {
        if (showDrawer) {


            CompanySvrc.getConfigKey('restrictConversationsToAdmins', function (err, restrictConversationsToAdmins) {

                setCanStartConversation(!restrictConversationsToAdmins || AuthSrvc.isADMIN() || AuthSrvc.isADVISOR());
            });
        } else {
            setConversations([]);
            setTotalConversations(0);
            setCurrentConversationsPage(1);
            setDisplayConversation(false);
            setConversationId(null);
            setConversationDate(null);
        }

    }, [currentConversationsPage, showDrawer, employeeToken]);


    const renderNewConversationButton = () => {
        if (canStartConversation && !displayConversation) {
            return (

                <Box sx={{ mt: 'auto', p: 2 }}>
                    <Button
                        variant='contained'
                        color='primary'
                        fullWidth
                        onClick={newConversation}
                    >
                        {$translate.instant('NEW_CONVERSATION')}
                    </Button>
                </Box>
            );
        }
    };

    const renderConversationListing = () => {
        if (!canStartConversation) {
            return (
                <></>
            );
        }

        if (displayConversation) {
            return (
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <HfConversation
                        personalTokenId={employeeToken}
                        conversationId={conversationId}
                        onConversationStarted={handleConversationStarted}
                    />
                </Box>
            );
        } else {
            return (
                <>
                    <Typography variant='h6'>
                        {$translate.instant('PERSON_ENGAGEMENT_CONTEXT_DETAIL_CONVERSATIONS')}
                    </Typography>
                    <Typography variant='caption' color='textSecondary'>
                        {$translate.instant('PERSON_ENGAGEMENT_CONTEXT_DETAIL_CONVERSATIONS_COUNT', {
                            count: totalConversations,
                        })}
                    </Typography>

                    {totalConversations > 0 ? (
                        <>
                            <Box mt={2}>
                                {conversations.map((conversation, index) => (
                                    <Box
                                        key={index}
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='space-between'
                                        mb={2}
                                    >

                                        <Stack direction='column' spacing={0.5}>
                                            <HfUserSmall userId={conversation.assignedTo} />
                                            <Typography variant='caption'>
                                                {moment(Dates.fromAPIFormat(conversation.createdOn, APISettings.apiDateFormat)).format('DD MMM YYYY')}
                                            </Typography>
                                        </Stack>


                                        {conversation.canView ? (
                                            <Button
                                                variant='text'
                                                size='small'
                                                onClick={() =>
                                                    viewConversation(conversation.id, Dates.fromAPIFormat(conversation.createdOn, APISettings.apiDateFormat))
                                                }
                                            >
                                                {$translate.instant('VIEW_CONVERSATION')}
                                            </Button>
                                        ) : (
                                            <Typography variant='caption' color='error'>
                                                {$translate.instant(
                                                    'VIEW_CONVERSATION_NOT_ALLOWED_DESCRIPTION'
                                                )}
                                            </Typography>
                                        )}
                                    </Box>
                                ))}
                            </Box>

                            {totalConversations > 5 && (
                                <Box display='flex' justifyContent='center' mt={2}>
                                    <Pagination
                                        count={Math.ceil(totalConversations / 5)}
                                        page={currentConversationsPage}
                                        onChange={(event, page) => setCurrentConversationsPage(page)}
                                    />
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box textAlign='center' mt={3}>
                            <EmptyState
                                image='images/empty-state-comments.png'
                                small
                                message={$translate.instant(
                                    'PERSON_ENGAGEMENT_NO_CONVERSATIONS_TITLE'
                                )}
                                submessage={$translate.instant(
                                    'PERSON_ENGAGEMENT_NO_CONVERSATIONS_DESCRIPTION'
                                )}
                            />
                        </Box>
                    )}
                </>
            );
        }
    };


    if (!showDrawer) {
        return null;
    }

    return (
        <TitleCloseableDrawer
            title={$translate.instant('PERSON_ENGAGEMENT_CONTEXT_DETAIL_TITLE')}
            anchor={'right'}
            open={showDrawer}
            onClose={onClose}
            drawerProps={{
                sx: {
                    width: '400px',
                    maxWidth: 'calc(100vw - 100px)',
                    minWidth: 'calc(400px)',
                    zIndex: 3000}
            }}
        >
            <Stack
                spacing={2}
                height={'100%'}
                sx={{
                    overflow: 'hidden', // prevent outer scroll
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {/* Header Section */}
                <Box>
                    {displayConversation && (
                        <Stack gap={1} direction={'row'} alignItems={'center'}>
                            <IconButton onClick={closeConversation}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography variant='h4'>
                                {!conversationId
                                    ? $translate.instant('PERSON_ENGAGEMENT_START_CONVERSATION')
                                    : $translate.instant('PERSON_ENGAGEMENT_CONVERSATION_DETAILS')}
                            </Typography>
                        </Stack>
                    )}

                    {!conversationDate && (
                        <Typography variant='caption' color='textSecondary'>
                            {$translate.instant('PERSON_ENGAGEMENT_CONTEXT_DETAIL_AS_OF', {
                                to: moment(to).format('MMM YYYY'),
                            })}
                        </Typography>
                    )}

                    {conversationDate && (
                        <HfBannerReact type='warning'>
                            <Typography variant='body2'>
                                {$translate.instant('PERSON_ENGAGEMENT_AS_ON_CONVERSATION_STARTS', {
                                    to: moment(Dates.fromAPIFormat(conversationDate, APISettings.apiDateFormat)).format('MMM YYYY'),
                                })}
                            </Typography>
                        </HfBannerReact>
                    )}
                </Box>

                {/* Scrollable Content Section */}
                <Box sx={{
                    flexGrow: 1,
                    overflow: 'auto', // enable scroll for content
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <PersonEngagementContext
                        personToken={employeeToken}
                        maxScoreRows={6}
                        from={statusFrom}
                        to={statusTo}
                    />

                    {renderConversationListing()}



                </Box>

                {renderNewConversationButton()}


            </Stack>
        </TitleCloseableDrawer>
    );
};

export default PersonEngagementStatusContextModal;
