import * as React from 'react';
import { Stack, Typography } from '@mui/material';
import { getService } from 'reactInAngular';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FeedbackStats } from '../../../../shared/types/feedback';
import Style from '../../../../utilities/style.utils';

interface FeedbackPositivenessChartProps {
    stats: FeedbackStats | null;
}

const FeedbackPositivenessChart: React.FC<FeedbackPositivenessChartProps> = ({ stats }) => {
    const $translate = getService('$translate');

    // function to generate color based on value
    const getColor = (value: number) => {
        if (value <= 5) {
            // interpolate between bad and warning for values 0-5
            const fraction = value / 5;
            return Style.colorGradient(fraction, Style.bad, Style.warning);
        } else {
            // interpolate between warning and good for values 6-10
            const fraction = (value - 6) / 4;
            return Style.colorGradient(fraction, Style.warning, Style.good);
        }
    };

    // prepare chart options
    const getChartOptions = (positivenessData: { [key: number]: number | undefined }) => {
        // create an array with all values from 0 to 10
        const completeData = Array.from({ length: 11 }, (_, index) => ({
            y: positivenessData[index] || 0,
            color: getColor(index)
        }));

        return {
            chart: {
                type: 'column',
                height: 250,
                width: 250,
                spacing: [10, 10, 10, 10]
            },
            title: {
                enabled: false,
                text: null
            },
            xAxis: {
                categories: Array.from({ length: 11 }, (_, i) => i.toString()),
                labels: {
                    enabled: false
                },
                title: {
                    enabled: false
                },
                lineWidth: 0,
                tickWidth: 0
            },
            yAxis: {
                title: {
                    enabled: false
                },
                labels: {
                    enabled: true,
                    style: {
                        color: Style.charts.textColor,
                        fontFamily: Style.charts.fontFamily,
                        fontSize: Style.charts.fontSmall.size
                    }
                },
                gridLineWidth: 1,
                gridLineColor: Style.Neutral200
            },
            plotOptions: {
                column: {
                    borderRadius: 5,
                    pointPadding: 0,
                    groupPadding: 0,
                    borderWidth: 0
                }
            },
            series: [{
                name: $translate.instant('COMMENTS'),
                data: completeData,
                showInLegend: false
            }],
            credits: {
                enabled: false
            },
            tooltip: {
                formatter: function() {
                    return `${$translate.instant('COMMENTS_POSITIVENESS_RANGE')}: ${this.x}<br/>${$translate.instant('COMMENTS')}: ${this.y}`;
                }
            }
        };
    };

    return (
        <Stack gap={1} alignItems='center'>

            {stats?.positivenessCount && (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={getChartOptions(stats.positivenessCount)}
                />
            )}
        </Stack>
    );
};

export default FeedbackPositivenessChart;
