import React, { useState } from 'react';
import {
    Box,
    Typography,
    IconButton,
    Tooltip, Stack,
} from '@mui/material';
import { Info, ArrowBack } from '@mui/icons-material';


interface DashboardHeaderProps {
    hfTitle: string;
    hfSubtitle: string;
    hfInfo?: string;
    showBackArrow?: boolean;
    section?: string;
    actions?: React.ReactNode;
    subHeader?: React.ReactNode;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
    hfTitle,
    hfSubtitle,
    hfInfo,
    showBackArrow,
    actions,
    subHeader,
}) => {

    const handleArrowClick = () => {
        window.history.back();
    };

    return (
        <Stack gap={0}
            marginLeft={'-15px'} /* TODO: remove this */
            marginRight={'-15px'} /* TODO: remove this */

            sx={{
                backgroundColor: 'white',
                borderBottom: '1px solid #DBDEE0',
                minHeight: 90,
                paddingTop: 1,
                paddingBottom: 1,

            }}
        >
            <Stack direction='row' justifyContent='space-between' alignItems='center'>


                <Stack direction='row' alignItems='center' ml={2} mr={2}>


                    <Box flex='1'>

                        <Stack direction='row' gap={0}>
                            {showBackArrow && (
                                <IconButton onClick={handleArrowClick} size='small'>
                                    <ArrowBack />
                                </IconButton>
                            )}
                            <Typography variant='h1' noWrap>
                                {hfTitle}
                                {hfInfo && (
                                    <Tooltip title={hfInfo}>
                                        <Info color='success' style={{ marginLeft: 8 }} />
                                    </Tooltip>
                                )}
                            </Typography>
                        </Stack>

                        <Typography variant='body3' color='text.secondary'>
                            {hfSubtitle}
                        </Typography>
                    </Box>

                </Stack>

                <Box>
                    {actions}
                </Box>
            </Stack>

            {subHeader && (
                <Box  ml={2} mr={2} mt={1}>
                    {subHeader}
                </Box>
            )}
        </Stack>

    );
};


export default DashboardHeader;
