import FeedbackCommentsView from './feedback_comments.tsx';
import {angularize} from 'reactInAngular';

// Import the component from the React -> and wrap it with the angularize function
angularize(FeedbackCommentsView, 'hfFeedbackComments', angular.module('happyForceApp'));

const FeedbackCommentsAngular = function ManageUsersRoute($stateProvider) {
    'ngInject';
    $stateProvider.state('feedback.comments', {
        url: '/',
        template: '<hf-feedback-comments></hf-feedback-comments>',
        data: { pageTitle: 'COMMENTS_DETAIL', roles: ['MANAGER', 'ADMIN', 'ADVISOR'] }
    });
}
export default FeedbackCommentsAngular;