
const ReportsService = function (Restangular, $log, Rollbar, Settings) {
    'ngInject';
    var Reports = Restangular.one('company').one('reports');

    var methods = {};

    methods.question = function (params, callback, error) {

        Reports.one('question')
            .one('from', moment(params.from).format(Settings.apiDateFormat))
            .one('to', moment(params.to).format(Settings.apiDateFormat))
            .customPOST('', '', params || null, {})
            .then(callback, error);
    };



    methods.hi = function (params, callback, error) {

        Reports.one('hi')
            .one('from', moment(params.from).format(Settings.apiDateFormat))
            .one('to', moment(params.to).format(Settings.apiDateFormat))
            .customPOST('', '', params || null, {})
            .then(callback, error);
    };

    methods.comments = function (params, callback, error) {
        var finalParams = angular.copy(params);
        if (params.from)
            finalParams.from = moment(params.from).format(Settings.apiDateFormat);
        if (params.to)
            finalParams.to = moment(params.to).format(Settings.apiDateFormat);
        Reports.one('comments')
            .customPOST('', '', finalParams || null, {})
            .then(callback, error);
    };


    methods.recognitions = function (params, callback, error) {
        var finalParams = angular.copy(params);
        if (params.from)
            finalParams.from = moment(params.from).format(Settings.apiDateFormat);
        if (params.to)
            finalParams.to = moment(params.to).format(Settings.apiDateFormat);
        Reports.one('recognitions')
            .customPOST('', '', finalParams || null, {})
            .then(callback, error);
    };

    methods.participation = function (from, to, params, callback, error) {
        Reports.one('participants')
            .one('from', moment(from).format(Settings.apiDateFormat))
            .one('to', moment(to).format(Settings.apiDateFormat))
            .customPOST('', '', params || null, {})
            .then(callback, error);
    };

    methods.activation = function (from, to, params, callback, error) {
        Reports.one('activation')
            .one('from', moment(from).format(Settings.apiDateFormat))
            .one('to', moment(to).format(Settings.apiDateFormat))
            .customPOST('', '', params || null, {})
            .then(callback, error);
    };

    methods.users = function (callback) {
        Reports.customPOST('', 'people', {}, {}).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.global = function (date, callback, error) {
        Reports.one('global')
            .one(moment(date).format(Settings.apiDateFormat))
            .customPOST('', '', null, {})
            .then(callback, error);
    };


    methods.survey = function (surveyId, type, params, callback, error) {
        var finalParams = angular.copy(params);
        if (params.from)
            finalParams.from = moment(params.from).format(Settings.apiDateFormat);
        if (params.to)
            finalParams.to = moment(params.to).format(Settings.apiDateFormat);
        Reports.one('survey').one(surveyId).one(type)
            .customPOST('', '', finalParams || null, {})
            .then(callback, error);
    };


    methods.engagement = function (from, to, grouping, callback) {
        const apiFormatFrom = moment(from).format(Settings.apiDateFormat);

        const apiFormatTo = moment(to).format(Settings.apiDateFormat);

        Reports.one('engagement').one('from').one(apiFormatFrom).one('to').one(apiFormatTo).one('grouping').one(grouping)
            .customPOST('', '', null, {})
            .then(function (result) {
                callback(null, result);
            }, function (err) {
                callback(err);
            });
    };

    methods.score = function (scoreId, from, to, params, callback) {
        const apiFormatFrom = moment(from).format(Settings.apiDateFormat);
        const apiFormatTo = moment(to).format(Settings.apiDateFormat);

        Reports.one('score').one(scoreId).one('from').one(apiFormatFrom).one('to').one(apiFormatTo)
            .customPOST('', '', params, {})
            .then(function (result) {
                callback(null, result);
            }, function (err) {
                callback(err);
            });
    };


    return methods;

};
export default ReportsService;
