import React from 'react';
import angular from 'angular';
import { angularize } from 'reactInAngular';
import { TitleCloseableDrawer } from '../hf-title-closeable-drawer/hfTiteCloseableDrawer';
import HFImageSearch from '../hf-image-search/hfImageSearch';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { getService } from 'reactInAngular';
import HFSwitch from '../hf-switch/hf-switch.component';
import HFImageUpload from '../hf-image-upload/hfImageUpload';

interface ImageSearchModalProps {
    open: boolean;
    onClose: () => void;
    currentImage?: string;
    defaultImage?: string;
    onSelect?: (image: string | null) => void;
}

const HFImageSearchModal: React.FC<ImageSearchModalProps> = ({
    open,
    onClose,
    currentImage,
    defaultImage,
    onSelect
}) => {
    const $translate = getService('$translate');
    const $intercom = getService('$intercom');
    const Attachments = getService('Attachments');
    const toaster = getService('toaster');
    const [selectedImage, setSelectedImage] = React.useState<string | File | null>(null);

    const [isUploading, setIsUploading] = React.useState(false);

    const [mode, setMode] = React.useState<'select' | 'upload'>('select');

    React.useEffect(() => {
        if (open) {
            $intercom.update({ hide_default_launcher: true });
        }
        return () => {
            $intercom.update({ hide_default_launcher: false });
        };
    }, [open]);

    const handleImageSelect = (image: string | File | null) => {
        setSelectedImage(image);
    };

    const handleClose = () => {
        if (onSelect) {

            // if the image is a file, we need to upload it to the server
            if (selectedImage instanceof File) {

                setIsUploading(true);


                Attachments.upload(selectedImage, (error, uploadedFile, uploadProgress) => {
                    setIsUploading(false);

                    if (error) {
                        toaster.error($translate.instant('IMAGE_SELECT_UPLOAD_ERROR'));
                    } else {
                        onSelect(uploadedFile[0].filePath);

                    }
                });
            } else {
                onSelect(selectedImage);

            }
        }

    };

    return (
        <TitleCloseableDrawer
            open={open}
            onClose={onClose}
            title={$translate.instant('IMAGE_SELECT')}
            drawerProps={{
                sx: {
                    width: '400px',
                    maxWidth: 'calc(100vw - 100px)',
                    minWidth: 'calc(400px)',
                    zIndex: 3000}
            }}
        >
            <Stack spacing={2} height={'100%'}>

                <Typography variant='body3' fontWeight={'bold'}>
                    {$translate.instant('IMAGE_SELECT_CURRENT')}
                </Typography>


                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '200px'
                }}>
                    {currentImage ? (
                        <img
                            src={currentImage}
                            alt='Current'
                            style={{ maxWidth: '100%', maxHeight: '200px' }}
                        />
                    ) : defaultImage ? (
                        <img
                            src={defaultImage}
                            alt='Default'
                            style={{ maxWidth: '100%', maxHeight: '200px' }}
                        />
                    ) : (
                        <Typography variant='body3' fontWeight={'bold'} color={'text.warning'}>
                            {$translate.instant('IMAGE_SELECT_CURRENT_NO_IMAGE')}
                        </Typography>
                    )}
                </Box>


                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <HFSwitch
                        firstLabel=  {$translate.instant('IMAGE_SELECT_SEARCH_STOCK')}
                        firstValue={'select'}
                        secondLabel=  {$translate.instant('IMAGE_SELECT_UPLOAD')}
                        secondValue={'upload'}
                        onSelect={(value: 'select' | 'upload') => setMode(value)}
                        selected={mode}
                    />

                </Box>

                <Typography variant='body3' fontWeight={'bold'}>
                    {$translate.instant('IMAGE_SELECT_NEW')}
                </Typography>


                {mode === 'select' && (

                    <HFImageSearch
                        onSelect={handleImageSelect}
                        currentImage={currentImage}
                        defaultImage={defaultImage}
                        numResults={6}
                        small={true}
                    />
                )}

                {mode === 'upload' && (
                    <HFImageUpload
                        onSelect={handleImageSelect}
                        currentImage={currentImage}
                        defaultImage={defaultImage}
                    />
                )}


                {isUploading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress size={20} variant='indeterminate' />
                    </Box>
                )}

                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleClose}
                    disabled={!selectedImage || isUploading}
                >
                    {$translate.instant('IMAGE_SELECT_DONE')}
                </Button>

            </Stack>
        </TitleCloseableDrawer>
    );
};

angularize(HFImageSearchModal, 'hfImageSearchModal', angular.module('happyForceApp'), {
    open: '<',
    onClose: '<',
    currentImage: '<',
    defaultImage: '<',
    onSelect: '<'
});

export default HFImageSearchModal;
