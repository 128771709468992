import React from 'react';
import {
    styled,
    IconButton,
    Typography,
    Box,
    DrawerProps,
    BoxProps, Stack, SxProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {PaddedDrawer} from '../hf-styled-components/PaddedDrawer';

interface TitleCloseableDrawerProps {
    onClose: () => void;
    title: string | React.ReactNode;
    headerProps?: BoxProps;
    open: boolean;
    anchor?: DrawerProps['anchor'];
    children: React.ReactNode;
    drawerProps?: DrawerProps;
}


const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const TitleCloseableDrawer: React.FC<TitleCloseableDrawerProps> = ({
    open,
    onClose,
    title,
    children,
    headerProps,
    ...drawerProps
}) => {


    return (
        <PaddedDrawer
            anchor='right'
            open={open}
            onClose={onClose}
            {...drawerProps}
        >
            <Header {...headerProps}>


                <Stack
                    direction='row'
                    gap={1}
                    sx={{
                        width: '100%',
                        alignItems: 'baseline',
                        justifyContent: 'space-between',
                    }}
                >

                    {typeof title === 'string' ? (
                        <Typography variant='h4' component='div'>
                            {title}
                        </Typography>
                    ) : (
                        title
                    )}



                    <IconButton onClick={onClose} aria-label='close'>
                        <CloseIcon />
                    </IconButton>
                </Stack>

            </Header>
            <Box paddingTop={1} height={'100%'}>{children}</Box>
        </PaddedDrawer>
    );
};

export { TitleCloseableDrawer };
