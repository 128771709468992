
import { doPostAsync } from './axiosWrapper';
import { ConversationStartersRequestDTO } from '../../types/conversations';


const statsBaseUrl = '/company/conversations';




export const getConversationIceBreakersForComment = (commentId: string) => {
    const data: ConversationStartersRequestDTO = {
        commentId: commentId,
        originType: 'COMMENT'
    };


    const url = `${statsBaseUrl}/starters`;
    return doPostAsync(url, data, {});
};


export const getConversationIceBreakersForPersonStatus = (personKey: string, statusDate: string) => {
    const data: ConversationStartersRequestDTO = {
        personKey: personKey,
        statusDate: statusDate,
        originType: 'ENGAGEMENT_STATUS'
    };

    const url = `${statsBaseUrl}/starters`;
    return doPostAsync(url, data, {});
};
