import {Drawer, styled} from '@mui/material';
import {paperClasses} from '@mui/material/Paper';


const PaddedDrawer = styled(Drawer)(({ theme, sx }) => ({
    [`& .${paperClasses.root}`]: {
        ...sx,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),

    }
}));




export { PaddedDrawer};
