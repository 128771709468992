import * as React from 'react';
import {angularize} from 'reactInAngular';
import angular from 'angular';

interface AnnouncementAttachment {
    id: string;
    name: string;
    fileSize: number;
    fileType: string;
}

interface AnnouncementAttachmentsProps {
    attachments: AnnouncementAttachment[];
    onAttachmentRemoved?: (attachmentId: string) => void;
}

const AnnouncementAttachments =  function ({ attachments, onAttachmentRemoved }: AnnouncementAttachmentsProps) {

    function deleteAttachment(attachmentId: string) {
        if (onAttachmentRemoved) {
            onAttachmentRemoved(attachmentId);
        }
    }

    if (!attachments || !attachments.length) {
        return null;
    } else {
        return (
            <div id='files'>
                <div id='attachments' className='announcement-attachments' style={{ display: attachments.length ? 'block' : 'none' }}>
                    {attachments?.map((attachment) => (
                        <div key={attachment.id} className='attachment-container'>
                            <div className='attachment'>
                                <div className='fileinfo'>
                                    <div className='close' onClick={() => deleteAttachment(attachment.id)}>
                                        <i className='fa fa-times'></i>
                                    </div>
                                    <p className='filename small-style font-bold truncate'>{attachment.name}</p>
                                    <p className='filesize mini-style'>{`${attachment.fileSize}Kb`}</p>
                                </div>
                                <div className='filetype'>{attachment.fileType}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
};


/* This is our trick for expose these components on angular */
angularize(AnnouncementAttachments, 'hfAnnouncementAttachments', angular.module('happyForceApp'), {
    attachments: '<',
    onAttachmentRemoved: '<',
    length: '<'
});

export default AnnouncementAttachments;
