

// Here we go with pure react components; we dont
import DashboardView from './dashboard/dashboard.controller';
import AccessView from './access/access.controller';
import AnnouncementsView from './announcements/announcements.controller';
import EngagementView from './engagement/engagement.angular';

import FeedbackView from './feedback/feedback.controller';
import OnboardingView from './onboarding/onboarding.controller';
import ActivationView from './reports/activation/analytics_activation.controller';
import ManagersRankingView from './reports/managers_ranking/managers_ranking.controller';
import AnalyticsParticipationView from './reports/participation/analytics_participation.controller';
import SurveysView from './surveys/surveys.controller';
import SurveysTemplateSelectorView from './surveys/surveys_template_selector/surveys_template_selector.controller';
import SurveysEditView from './surveys/surveys_edit/surveys_edit.controller';
import LoginView from './login/login.controller';
import TopicsDetailView from './topics/topics_detail/topics_detail.controller';
import CheckinsView from './topics/topics.controller';
import SurveyResultsView from './surveys/survey_results/survey_results.controller';
import SettingsView from './settings/company.configuration.controller';
import LifeCycleView from './custom/life_cycle.controller';


import AdvisorsToolsView from './advisors/advisors_tools/advisors_tools.controller';
import AdvisorsTemplateView from './advisors/advisors_template/advisors_template.controller';
import AdvisorsSurveysView from './advisors/advisors_surveys/advisors_surveys.controller';
import AdvisorsSurveysEditView
    from './advisors/advisors_surveys/advisors_surveys_edit/advisors_surveys_edit.controller';
import Engagement2View from 'root/app/components/engagement_beta/egagement_beta.view';

//Alrready in React
import ManageUsersView from './users/manage.users.angular';
import ManageUsersBulkView from './users/bulk/manage.bulk.angular';


import FeedbackCommentsAngular from './feedback/feedback-comments/feedback-comments.angular';





// Boostrap all views
angular.module('happyForceApp')
    .config(SurveyResultsView)
    .config(CheckinsView)
    .config(TopicsDetailView)
    .config(LoginView)
    .config(SurveysEditView)
    .config(SurveysTemplateSelectorView)
    .config(ManageUsersBulkView)
    .config(AnalyticsParticipationView)
    .config(ManagersRankingView)
    .config(ActivationView)
    .config(OnboardingView)
    .config(FeedbackView)
    .config(ManageUsersView)
    .config(EngagementView)
    .config(AnnouncementsView)
    .config(DashboardView)
    .config(AccessView)
    .config(SurveysView)
    .config(SettingsView)
    .config(LifeCycleView)
    .config(AdvisorsToolsView)
    .config(AdvisorsTemplateView)
    .config(AdvisorsSurveysView)
    .config(AdvisorsSurveysEditView)
    .config(Engagement2View)
    .config(FeedbackCommentsAngular);


