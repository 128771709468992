import React, {useState, useCallback, useEffect} from 'react';
import { getService } from 'reactInAngular';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import EngagementHIBox from './components/hi_box/hiBox';

import EngagementScoresFeedbackBox from './components/feedback_box/EngagementFeedbackBox';
import EngagementHeatmap from './components/engagement_heatmap/engagement_heatmap';
import {ButtonGroup, Stack} from '@mui/material';
import DashboardHeader from '../../shared/components/_migrated/dashboard-header/hfDashboardHeader';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import {WhiteBgPaper} from '../../shared/new-components/hf-styled-components/WhiteBgPaddedPapper';
import EngagementAnalyzeBox from './components/analyze_box/EngagementAnalizeBox';
import EngagementENPSBox from './components/enps_box/enpsBox';
import EngagementPrioritiesBox from './components/priorities_box/PrioritiesBox';
import EngagementDateBrowser from './shared/date_browser/EngagementDateBrowser';
import {DateGrouping} from '../../shared/types/common';
import HIDetailDrawer, {HIDetailShowParams} from './hi_detail/HIDetailDrawer';
import ENPSDetailDrawer, {ENPSDetailShowParams} from './enps_detail/ENPSDetailDrawer';
import ScoreDetailDrawer, {ScoreDetailShowParams} from './score_detail/ScoreDetailDrawer';
import {CommonSegmentationFilters} from '../../shared/new-components/hf-filter-menu/types';
import EngagementScoresSummaryBox from './components/scores_summary_box/ScoresSumaryBox';


const EngagementView: React.FC = () => {


    const $translate = getService('$translate');
    const Filters = getService('Filters');
    const Analytics = getService('Analytics');
    const toaster = getService('toaster');

    const [to, setTo] = useState<Date>(Filters.endDate());
    const [summaryView, setSummaryView] = useState<number>(2);
    const [exportingToExcel, setExportingToExcel] = useState<boolean>(false);
    const [grouping, setGrouping] = useState<DateGrouping>(DateGrouping.MONTH);


    const [hiDrawerParams, setHiDrawerParams] = useState<HIDetailShowParams>(null);

    const [enpsDrawerParams, setEnpsDrawerParams] = useState<ENPSDetailShowParams>(null);

    const [scoreDrawerParams, setScoreDrawerParams] = useState<ScoreDetailShowParams>(null);


    const exportToExcel = useCallback(() => {
        setExportingToExcel(true);
        const EngagementReportModal = getService('EngagementReportModal');
        EngagementReportModal.showModal(to, () => {
            toaster.pop('success', null, $translate.instant('ENGAGEMENT_EXPORT_SENT'));
            Analytics.trackEvent('engagement', 'excel_report', 'success');
        });
        setExportingToExcel(false);
    }, [to]);



    const handleShowScoresDetail = (scoreId: string,
        to?: Date,
        filters?: CommonSegmentationFilters) => {


        setScoreDrawerParams({
            visible: true,
            scoreId,
            to,
            filters,
            dateGrouping: grouping,
        });
    };

    const handleShowENPSDetail = (filters?: CommonSegmentationFilters) => {
        setEnpsDrawerParams({
            visible: true,
            filters,
            to,
            dateGrouping: grouping,
        });

    };

    const handleShowHIDetail = (filters?: CommonSegmentationFilters) => {
        setHiDrawerParams({
            visible: true,
            filters,
            to,
            dateGrouping: grouping,
        });

    };

    const handleShowInsightDetail = (to: Date, scoreId: string, filters?: CommonSegmentationFilters) => {
        setScoreDrawerParams({
            visible: true,
            scoreId,
            to,
            filters,
            dateGrouping: grouping,
        });
    };


    return (
        <>
            <Stack gap={2}>

                <DashboardHeader
                    hfTitle={$translate.instant('ENGAGEMENT_SECTION_TITLE')}
                    hfSubtitle={$translate.instant('ENGAGEMENT_SECTION_DESCRIPTION')}
                    actions={
                        <Stack direction='row' spacing={2}>
                            <EngagementDateBrowser
                                to={to}
                                dateGrouping={grouping}
                                onDateChange={setTo}
                                showYears={true}
                                showQuarters={true}
                                onDateGroupingChange={setGrouping} />


                            <Button
                                variant='contained'
                                startIcon={<FileDownloadRoundedIcon />}
                                onClick={exportToExcel}
                            >
                            </Button>
                        </Stack>
                    }
                />


                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={5}>
                                <WhiteBgPaper>
                                    <EngagementHIBox
                                        to={to}
                                        onShowDetail={() => handleShowHIDetail()}
                                        dateGrouping={grouping}/>
                                </WhiteBgPaper>
                                <WhiteBgPaper sx={{marginTop: 2}}>
                                    <EngagementENPSBox to={to}
                                        onShowDetail={() => handleShowENPSDetail()}
                                        dateGrouping={grouping}/>
                                </WhiteBgPaper>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <WhiteBgPaper>
                                    <EngagementScoresSummaryBox to={to}
                                        onShowDetail={(scoreId) => handleShowScoresDetail(scoreId)}
                                        dateGrouping={grouping}/>
                                </WhiteBgPaper>
                            </Grid>
                        </Grid>

                        <WhiteBgPaper sx={{marginTop: 2}}>


                            <Box display={'flex'}
                                width={'100%'}
                                alignItems={'center'}
                                justifyContent={'center'}>
                                <ButtonGroup variant='outlined' aria-label='Basic button group'>
                                    <Button
                                        variant={summaryView === 1 ? 'contained' : 'outlined'}
                                        onClick={() => setSummaryView(1)}
                                    >
                                        {$translate.instant('ENGAGEMENT_INSIGHTS')}
                                    </Button>
                                    <Button
                                        variant={summaryView === 2 ? 'contained' : 'outlined'}
                                        onClick={() => setSummaryView(2)}
                                    >
                                        {$translate.instant('ENGAGEMENT_HEATMAP')}
                                    </Button>
                                    <Button
                                        variant={summaryView === 3 ? 'contained' : 'outlined'}
                                        onClick={() => setSummaryView(3)}
                                    >
                                        {$translate.instant('ENGAGEMENT_ANALIZE')}
                                    </Button>
                                </ButtonGroup>
                            </Box>



                            <Box sx={{ marginTop: 3 }}>
                                {summaryView === 1 && (
                                    <EngagementPrioritiesBox
                                        to={to}
                                        dateGrouping={grouping}
                                        showFiltering={true}
                                        onViewDetailLabel={$translate.instant('ENGAGEMENT_VIEW_DETAIL')}
                                        showInsightDetail={handleShowInsightDetail}/>
                                )}
                                {summaryView === 2 && (
                                    <EngagementHeatmap to={to}

                                        dateGrouping={grouping}
                                        onShowENPSDetail={handleShowENPSDetail}
                                        onShowScoreDetail={(scoreId, filters) => handleShowScoresDetail(scoreId, undefined, filters)}
                                        onShowHIDetail={handleShowHIDetail}/>
                                )}
                                {summaryView === 3 && (
                                    <EngagementAnalyzeBox to={to} dateGrouping={grouping} />
                                )}
                            </Box>
                        </WhiteBgPaper>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <WhiteBgPaper>
                            <EngagementScoresFeedbackBox
                                dateGrouping={grouping}
                                to={to}
                                mode={'ALL'}
                                hasEnoughActiveEmployees/>
                        </WhiteBgPaper>
                    </Grid>
                </Grid>
            </Stack>
            <HIDetailDrawer showParams={hiDrawerParams}
                onClose={() => {
                    setHiDrawerParams(null);
                }}/>

            <ENPSDetailDrawer showParams={enpsDrawerParams} onClose={() => {
                setEnpsDrawerParams(null);
            }}/>

            <ScoreDetailDrawer showParams={scoreDrawerParams} onClose={() => {
                setScoreDrawerParams(null);

            }}/>
        </>

    );
};


export default EngagementView;
