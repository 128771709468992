import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import sunburst from 'highcharts/modules/sunburst';
import drilldown from 'highcharts/modules/drilldown';
import { getService } from 'reactInAngular';
import { Box, Stack, Typography, Chip } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { VOECategoryStats } from '../../../../shared/types/feedback';
import { getVoiceOfEmployee } from '../../../../shared/services/migrated/comments.services';
import ColorUtilities from '../../../../utilities/color.utilities';
import { FilterSelected } from '../../../../shared/new-components/hf-filter-menu/types';
import { buildCommonParams } from '../utils/buildParams';
import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';
import EmptyState from '../../../../shared/components/_migrated/empty_state/empty_state';

// initialize modules
if (typeof Highcharts === 'object') {
    sunburst(Highcharts);
    drilldown(Highcharts);
}

interface VOEChartData {
    id: string;
    name: string;
    parent?: string;
    value?: number;
    showInLegend: boolean;
    color?: string;
}

interface BreadcrumbItem {
    id: string;
    name: string;
}

const transformToChartData = (stats: VOECategoryStats[], parentId?: string): VOEChartData[] => {
    let currentColorIndex = 0;

    const Languages = getService('Languages');

    return stats.reduce<VOEChartData[]>((acc, stat) => {
        // add current node
        currentColorIndex = (currentColorIndex + 1) % ColorUtilities.palettes.charts.length;
        const currentNode: VOEChartData = {
            id: stat.id,
            name: Languages.getTranslationFromUserLanguage(stat.name),
            value: stat.count,
            parent: parentId,
            showInLegend: !parentId, // only root nodes in legend
            color: !parentId ? ColorUtilities.palettes.charts[currentColorIndex] : undefined
        };
        acc.push(currentNode);

        // add subcategories recursively
        if (stat.subcategories.length > 0) {
            acc.push(...transformToChartData(stat.subcategories, stat.id));
        }

        return acc;
    }, []);
};

interface FeedbackVOEChartProps {
    filters: FilterSelected;
    searchText?: string;
    onCategoryClick?: (categoryId: string) => void;
}

const FeedbackVOEChart: React.FC<FeedbackVOEChartProps> = ({ filters, searchText, onCategoryClick }) => {
    const [chartData, setChartData] = useState<VOEChartData[]>([]);
    const [loading, setLoading] = useState(true);
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
    const chartRef = React.useRef<HighchartsReact.RefObject>(null);
    const $translate = getService('$translate');
    const ErrorSvrc = getService('ErrorSvrc');

    useEffect(() => {
        setLoading(true);
        const params = {
            ...buildCommonParams(filters),
            commentText: searchText || undefined
        };
        getVoiceOfEmployee(params).then((stats) => {
            const transformedData = transformToChartData(stats);
            setChartData(transformedData);
        }).catch((error) => {
            ErrorSvrc.showErrorModal(error);
        }).finally(() => {
            setLoading(false);
        });
    }, [filters, searchText]);

    const handleCategoryClick = (point: { id: string; name: string; doDrilldown: () => void }) => {
        // build the complete path from clicked point to root
        const path: BreadcrumbItem[] = [];
        let currentPoint = chartData.find(item => item.id === point.id);

        // add all points from current to root
        while (currentPoint) {
            path.unshift({ id: currentPoint.id, name: currentPoint.name });
            if (currentPoint.parent) {
                currentPoint = chartData.find(item => item.id === currentPoint.parent);
            } else {
                break;
            }
        }



        // update breadcrumbs
        setBreadcrumbs(path);

        point.doDrilldown();


        //  debugger;

        /* // get the chart instance and perform drill-down
        const chart = chartRef.current?.chart;
        if (chart) {
            const series = chart.series[0] as any;

            // first drill up to root; do it 10 times
            for (let i = 0; i < 10; i++) {
                series.drillUp();
            }

            // then drill down through the path
            path.forEach((item, index) => {
                if (index > 0) { // skip root level
                    const targetPoint = series.points.find((p: any) => p.id === item.id);
                    if (targetPoint) {
                        //           targetPoint.doDrilldown();
                    }
                }
            });
        }*/

        if (onCategoryClick) {
            onCategoryClick(point.id);
        }
    };

    const handleBreadcrumbClick = (index: number) => {

        //  debugger;

        const item = breadcrumbs[index];
        // update breadcrumbs to clicked position
        setBreadcrumbs(breadcrumbs.slice(0, index + 1));

        // get the chart instance
        const chart = chartRef.current?.chart;
        if (!chart) {return; }


        // get the series
        const series = chart.series[0] as any;
        // first drill up to root; do it 10 times
        for (let i = 0; i < 10; i++) {
            //      series.drillUp();
        }


        // then drill down to the target level
        if (item.id) {
            // find the point and trigger click
            const point = series.points.find((p: any) => p.id === item.id);
            if (point.doDrilldown) {
                point.doDrilldown();
                // point.firePointEvent('click');
            }
        }

        if (onCategoryClick) {
            onCategoryClick(item.id);
        }
    };

    const handleHomeClick = () => {
        // reset breadcrumbs
        setBreadcrumbs([]);

        // reset chart to initial state
        const chart = chartRef.current?.chart;
        if (!chart) {return; }

        // get the series and drill up to root
        const series = chart.series[0] as any;
        // first drill up to root; do it 10 times
        for (let i = 0; i < 10; i++) {
            series.drillUp();
        }

        if (onCategoryClick) {
            onCategoryClick('');
        }
    };

    const chartOptions: Highcharts.Options = {
        chart: {
            height: '100%',
            events: {
                drilldown: function() {
                    // we don't need to handle drilldown here anymore as it's handled in click
                },
                drillup: function() {
                    const series = (this.series[0] as any);
                    // if we've drilled up to root, reset breadcrumbs
                    if (!series.rootNode) {
                        setBreadcrumbs([]);
                        if (onCategoryClick) {
                            onCategoryClick('');
                        }
                    }
                }
            }
        },
        title: {
            text: null
        },
        exporting: {
            enabled: true
        },
        plotOptions: {
            sunburst: {
                showInLegend: false,
                allowTraversingTree: true,
                size: '100%',
                center: ['50%', '50%'],
                dataLabels: {
                    format: '{point.name}',
                    filter: {
                        property: 'innerArcLength',
                        operator: '>',
                        value: 16
                    },
                    rotationMode: 'circular',
                    style: {
                        textOverflow: 'ellipsis'
                    }
                },
                point: {
                    events: {
                        click: function(e: any) {
                            const point = e.point as { id: string; name: string; doDrilldown: () => void };
                            if (point.id) {
                                handleCategoryClick(point);
                            }
                        }
                    }
                }
            }
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    plotOptions: {
                        sunburst: {
                            dataLabels: {
                                enabled: true
                            }
                        }
                    }
                }
            }]
        },
        series: [{
            name: 'Voice of Employee',
            allowTraversingTree: true,
            type: 'sunburst',
            data: chartData,
            cursor: 'pointer',
            levels: [{
                level: 1,
                dataLabels: {
                    filter: {
                        property: 'outerArcLength',
                        operator: '>',
                        value: 64
                    }
                }
            }, {
                level: 2,
                colorVariation: {
                    key: 'brightness',
                    to: -0.3
                }
            }, {
                level: 3,
                colorVariation: {
                    key: 'brightness',
                    to: 0.3
                }
            }]
        }],
        legend: {
            enabled: false
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '{point.name}: <b>{point.value}</b>'
        }
    };

    return (<HappyLoading loading={loading}>
        {chartData.length === 0 && (
            <EmptyState
                small
                image='images/no_segments.svg'
                message={$translate.instant('NO_VOE_DATA')}
            />
        )}

        {chartData.length > 0 && (
            <Stack gap={2}>
                <Stack direction='row' spacing={1} alignItems='center' flexWrap='wrap'>
                    <Chip
                        onClick={handleHomeClick}
                        color={breadcrumbs.length === 0 ? 'primary' : 'default'}
                        size='small'
                        icon={<HomeIcon />}
                    />
                    {breadcrumbs.length > 0 && (
                        <>
                            <Typography color='textSecondary'>/</Typography>
                            {breadcrumbs.map((item, index) => (
                                <React.Fragment key={item.id}>
                                    {index > 0 && <Typography color='textSecondary'>/</Typography>}
                                    <Chip
                                        label={item.name}
                                        onClick={() => handleBreadcrumbClick(index)}
                                        color={index === breadcrumbs.length - 1 ? 'primary' : 'default'}
                                        size='small'
                                    />
                                </React.Fragment>
                            ))}
                        </>
                    )}
                </Stack>
                <Box sx={{ height: { xs: '50vh', sm: '60vh', md: '70vh' }, width: '100%' }}>
                    <HighchartsReact
                        ref={chartRef}
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </Box>
            </Stack>
        )}
    </HappyLoading>);
};

export default FeedbackVOEChart;
