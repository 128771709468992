import * as React from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { getService } from 'reactInAngular';
import { FeedbackStats } from '../../../../shared/types/feedback';
import { getCommentsStats } from '../../../../shared/services/migrated/comments.services';
import { FilterSelected } from 'src/app/shared/new-components/hf-filter-menu/types';
import { buildCommonParams } from '../utils/buildParams';

interface FeedbackStatsComponentProps {
    filters: FilterSelected;
    searchText?: string;
    voeCategory?: string;
    onStatsLoaded: (stats: FeedbackStats) => void;
}

const FeedbackStatsComponent: React.FC<FeedbackStatsComponentProps> = ({ filters, searchText, voeCategory, onStatsLoaded }) => {
    const $translate = getService('$translate');
    const ErrorSvrc = getService('ErrorSvrc');

    const [stats, setStats] = React.useState<FeedbackStats | null>(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);

        const params = {
            ...buildCommonParams(filters),
            statTypes: ['COUNT', 'INTERACTIONS', 'TAGS', 'AUTHORS', 'EMOTIONS', 'POSITIVENESS', 'FEEDBACK_TYPE', 'REPLIES', 'REFEREED'],
            commentText: searchText || undefined,
            voeCategories: voeCategory
        };



        getCommentsStats(params).then((stats) => {
            onStatsLoaded(stats);
            setStats(stats);
        }).catch((error) => {
            ErrorSvrc.showErrorModal(error);
        }).finally(() => {
            setLoading(false);
        });
    }, [filters, searchText, voeCategory]);

    const wrapWithSkeleton = (children: React.ReactNode, loading: boolean) => {
        return loading ? <CircularProgress size={22} /> : children;
    };

    return (
        <Stack gap={2}>
            <Stack gap={1}>
                <Stack direction={'row'} gap={2} justifyContent={'space-between'} alignItems={'center'}>
                    <Stack textAlign='center' gap={1} flex={1} alignItems={'center'}>
                        <img src='/images/ft_congratulate.svg' alt='Congratulations' style={{ height: 40 }} />
                        {wrapWithSkeleton(<Typography variant='h1' fontWeight={'bold'}>{stats?.feedbackTypeCount?.CONGRATULATION}</Typography>, loading)}
                        <Typography variant='body3'>{$translate.instant('FEEDBACK_TYPE_RESULT_CONGRATULATION')}</Typography>
                    </Stack>
                    <Stack textAlign='center' gap={1} flex={1} alignItems={'center'}>
                        <img src='/images/ft_information.svg' alt='Information' style={{ height: 40 }} />
                        {wrapWithSkeleton(<Typography variant='h1' fontWeight={'bold'}>{stats?.feedbackTypeCount?.INFORMATION}</Typography>, loading)}
                        <Typography variant='body3'>{$translate.instant('FEEDBACK_TYPE_RESULT_INFORMATION')}</Typography>
                    </Stack>
                    <Stack textAlign='center' gap={1} flex={1} alignItems={'center'}>
                        <img src='/images/ft_suggestion.svg' alt='Suggestions' style={{ height: 40 }} />
                        {wrapWithSkeleton(<Typography variant='h1' fontWeight={'bold'}>{stats?.feedbackTypeCount?.SUGGESTION}</Typography>, loading)}
                        <Typography variant='body3'>{$translate.instant('FEEDBACK_TYPE_RESULT_SUGGESTION')}</Typography>
                    </Stack>
                    <Stack textAlign='center' gap={1} flex={1} alignItems={'center'}>
                        <img src='/images/ft_complain.svg' alt='Criticism' style={{ height: 40 }} />
                        {wrapWithSkeleton(<Typography variant='h1' fontWeight={'bold'}>{stats?.feedbackTypeCount.CRITICISM}</Typography>, loading)}
                        <Typography variant='body3'>{$translate.instant('FEEDBACK_TYPE_RESULT_CRITICISM')}</Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Stack gap={1}>
                <Typography variant='h3' fontWeight={'bold'}>
                    {$translate.instant('COMMENTS_RESULTS_REACTIONS')}
                </Typography>
                <Stack direction={'row'} gap={2} justifyContent={'space-between'} alignItems={'center'}>
                    <Stack textAlign='center' gap={1} flex={1} alignItems={'center'}>
                        <Typography variant='h1' fontWeight={'bold'} color='warning.main'>
                            <i className='fa fa-thumbs-up' /> {stats?.likes}
                        </Typography>
                        <Typography variant='body3'>
                            {wrapWithSkeleton(<>{stats?.uniqueLikes} {$translate.instant('UNIQUE_AGREES')}</>, loading)}
                        </Typography>
                    </Stack>
                    <Stack textAlign='center' gap={1} flex={1} alignItems={'center'}>
                        <Typography variant='h1' fontWeight={'bold'} color='primary.main'>
                            <i className='fa fa-thumbs-down' /> {stats?.dislikes}
                        </Typography>
                        <Typography variant='body3'>
                            {wrapWithSkeleton(<>{stats?.uniqueDislikes} {$translate.instant('UNIQUE_DISAGREES')}</>, loading)}
                        </Typography>
                    </Stack>
                    <Stack textAlign='center' gap={1} flex={1} alignItems={'center'}>
                        <Typography variant='h1' fontWeight={'bold'} color='primary.main'>
                            <i className='fa fa-comments-o' /> {stats?.replies}
                        </Typography>
                        <Typography variant='body3'>
                            {wrapWithSkeleton(<>{stats?.replies} {$translate.instant('COMMENT_REPLIES')}</>, loading)}
                        </Typography>
                    </Stack>
                    <Stack textAlign='center' gap={1} flex={1} alignItems={'center'}>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <img src='/images/inapropiado.svg' alt='' style={{ height: 24 }} />
                            <Typography variant='h1' fontWeight={'bold'} color='primary.main'>
                                {stats?.inappropiates}
                            </Typography>
                        </Stack>
                        <Typography variant='body3'>
                            {wrapWithSkeleton(<>{stats?.replies} {$translate.instant('MARKED_AS_INAPPROPRIATE')}</>, loading)}
                        </Typography>
                    </Stack>
                    <Stack textAlign='center' gap={1} flex={1} alignItems={'center'}>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <img src='/images/fridge.png' alt='' style={{ height: 24 }} />
                            <Typography variant='h1' fontWeight={'bold'} color='primary.main'>
                                {stats?.refereed}
                            </Typography>
                        </Stack>
                        <Typography variant='body3'>
                            {wrapWithSkeleton(<>{stats?.refereed} {$translate.instant('MARKED_AS_REFEREED')}</>, loading)}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default FeedbackStatsComponent;
