import { NestedMenuItem } from 'mui-nested-menu';
import { ListItemText, Typography, Box, Slider, Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

/**
 * Props interface for the NumberRangeMenuItem component
 * @interface NumberRangeMenuItemProps
 * @property {boolean} [open] - Whether the parent menu is open
 * @property {number} fromValue - The lower bound of the range
 * @property {number} toValue - The upper bound of the range
 * @property {Function} onAddFilter - Callback function when the range changes
 * @property {string} title - The title of the menu item
 * @property {string} fromType - The type identifier for the lower bound
 * @property {string} toType - The type identifier for the upper bound
 * @property {boolean} nonRemovable - Whether the filter can be removed
 * @property {number} min - The minimum value allowed
 * @property {number} max - The maximum value allowed
 * @property {number} step - The step increment for the slider
 */
interface NumberRangeMenuItemProps {
    open?: boolean;
    fromValue: number;
    toValue: number;
    onAddFilter: (fromType: string, toType: string, values: [number, number]) => void;
    title: string;
    fromType: string;
    toType: string;
    nonRemovable: boolean;
    min: number;
    max: number;
    step: number;
}

/**
 * A menu item component that displays a range slider with two handles
 * Used for filtering numeric ranges (e.g., positiveness scores)
 * Implements debouncing to prevent excessive API calls while dragging
 */
const NumberRangeMenuItem = ({
    open,
    fromValue,
    toValue,
    onAddFilter,
    title,
    fromType,
    toType,
    min,
    max,
    step
}: NumberRangeMenuItemProps) => {
    // local state to handle the slider value changes
    // this provides immediate UI feedback while debouncing actual updates
    const [localValue, setLocalValue] = useState<[number, number]>([fromValue || min, toValue || max]);

    // sync local state with props when they change
    useEffect(() => {
        setLocalValue([fromValue || min, toValue || max]);
    }, [fromValue, toValue, min, max]);

    /**
     * Debounced version of onAddFilter that delays updates by 500ms
     * This prevents excessive API calls while the user is dragging the slider
     * Returns a cleanup function to clear the timeout if component updates before timeout completes
     */
    const debouncedOnAddFilter = useCallback(
        (values: [number, number]) => {
            const timeoutId = setTimeout(() => {
                onAddFilter(fromType, toType, values);
            }, 500);

            return () => clearTimeout(timeoutId);
        },
        [fromType, toType, onAddFilter]
    );

    /**
     * Handles slider value changes
     * Updates local state immediately for smooth UI
     * Triggers debounced update for actual data changes
     */
    const handleChange = (_event: Event, newValue: number | number[]) => {
        if (Array.isArray(newValue)) {
            setLocalValue([newValue[0], newValue[1]]);
            debouncedOnAddFilter([newValue[0], newValue[1]]);
        }
    };

    /**
     * Renders the slider component with its configuration
     * Uses Material-UI Slider for the range selection
     */
    const renderSlider = () => {
        // generate marks for each step between min and max
        const marks = Array.from(
            { length: (max - min) / step + 1 },
            (_, index) => ({
                value: min + (index * step),
                label: (min + (index * step)).toString()
            })
        );

        return (
            <Box sx={{ width: 200, p: 2, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Slider
                    value={localValue}
                    onChangeCommitted={handleChange}
                    min={min}
                    max={max}
                    step={step}
                    valueLabelDisplay='auto'
                    marks={marks}
                />
            </Box>
        );
    };

    return (
        <NestedMenuItem
            renderLabel={() => <ListItemText primary={
                <Typography variant='body3' paddingLeft={1}>
                    {title}
                </Typography>
            } />}
            parentMenuOpen={open}
        >
            {renderSlider()}
        </NestedMenuItem>
    );
};

export default NumberRangeMenuItem;
