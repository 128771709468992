'use strict';

import ActivationReportExportModal
    from "./components/activation-report-type-modal/activation_report_type_modal.component";

import template from './analytics_activation.html';
import activationDistribution from "./components/activation-distribution/activation-distribution.controller";
import activationHeatmap from "./components/activation-heatmap/activation-heatmap.controller";
import hfActivationPace from "./components/activation-pace-chart/activation-pace-chart.controller";
import hfAnalyticsActivation from "./analytics_activation.controller";
import {getHierarchyById} from "../../../shared/services/migrated/hierarchy.service";
import NoSegmentsEmptyState from '../../../shared/components/_migrated/no_segments_empty_state/no_segments_empty_state';
import CompanyName from '../../../shared/components/_migrated/company-name/company-name';

function AnalyticsActivationCtrl($scope, ErrorSvrc, Filters, Analytics, Characteristics, $stateParams, CompanySvrc, $intercom, $translate, ActivationReportExportModal) {
    'ngInject';

    var _this = this;

    _this.summaryView = 1;

    _this.dates = {
        from: Filters.startDate(),
        to: Filters.endDate()
    };
    _this.level = Filters.level() || null;
    _this.groups = Filters.groups() || [];
    _this.title = {};

    if ($stateParams.hierarchyId && $stateParams.hierarchyId !== 'top') {
        _this.level = {
            id: $stateParams.hierarchyId
        };

        getHierarchyById($stateParams.hierarchyId, function (err, hierarchy) {
            if (hierarchy) {
                _this.level = {
                    id: hierarchy.id,
                    name: hierarchy.name
                };
                Filters.save({ level: _this.level });
                _this.title.name = _this.level.name;
            }
        });
    }

    _this.arrow = {
        action: function () {
            window.history.back();
        }
    };

    _this.exportToExcel = function () {
        ActivationReportExportModal.showExportModal(_this.dates.from, _this.dates.to, _this.filters, _this.groups);
    };


    //Ñapa size
    setTimeout(function () {
        $(window).trigger('resize');
    }, 500);


    this.$onInit = function () {
        _this.exportingExcelReport = false;

        ['dates', 'level', 'groups', 'filters'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (_this.level && _this.level.id) {
                    _this.title.name = _this.level.name;
                } else {
                    CompanySvrc.getUserCompany(
                        function (err, company) {
                            if (err) {
                                _this.title.name = $translate.instant('COMPANY');
                            }
                            else {
                                _this.title.name = company.name;
                            }
                        }
                    );
                }
            }, true);
        });


        Analytics.trackEvent('analytics_activation');

        $intercom.trackEvent('dashboard_visited_activation');
    };
}




function ActivationView($stateProvider) {
    'ngInject';

    $stateProvider.state('reports.activation', {
        url: '/activation/:hierarchyId',
        controller: AnalyticsActivationCtrl,
        template: template,
        controllerAs: '$ctrl',
        params: {
            hierarchyId: { squash: true, value: null }
        },

        data: { pageTitle: 'SIDEMENU_PACE', roles: ['MANAGER', 'ADMIN', 'ADIVSOR'] }
    });
}

export default ActivationView;
