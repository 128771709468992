import {Box, ButtonGroup, Grid, Stack, Typography} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import { getService } from 'reactInAngular';
import moment from 'moment';
import EngagementDateBrowser from '../shared/date_browser/EngagementDateBrowser';
import Button from '@mui/material/Button';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import Numbers from '../../../utilities/number.utilities';
import Dates from '../../../utilities/date.utilities';
import {WhiteBgPaper} from '../../../shared/new-components/hf-styled-components/WhiteBgPaddedPapper';
import ScoreEngagementDetailOverview from './components/overview/score_overview.component';
import EngagementRankingBox from '../shared/ranking_box/ranking_box.controller';
import ScoreDetailFactorsList from './components/factors_list/factors_list';
import useScoreNames from '../../../shared/hooks/useScoreNames';
import HfDateGroupingFormat from '../../../shared/new-components/hf-date-format/hfDateGroupingFormat';
import {DataQuality, DateGrouping} from '../../../shared/types/common';
import BenchmarkBox from '../shared/benchmark_box/benchmark_box';
import EngagementScoresFeedbackBox from '../components/feedback_box/EngagementFeedbackBox';
import {
    CommonSegmentationFilters,
    GroupFiltersConfig,
    HierarchyFiltersConfig,
    SegmentFiltersConfig
} from '../../../shared/new-components/hf-filter-menu/types';
import HfFilterMenu from '../../../shared/new-components/hf-filter-menu/hfFilterMenu';
import { toSegmentParams } from '../shared/utils';
import EmptyState from '../../../shared/components/_migrated/empty_state/empty_state';
import {ScoreResult, ScoreStatsResults} from '../../../shared/types/scores';
import ScorePositioningBox from './components/positioning_box/score_positioning_box.component';
import EngagementPrioritiesBox from '../components/priorities_box/PrioritiesBox';
import MeasuringNowBox from '../shared/measuring_now_box/EngagementMeasuringNow';
import QualityPopoverText from '../shared/quality_popover/EngagementQualityPopover';
import EngagementDetailEvolutionChart from '../shared/evolution_chart/EngagementEvolutionChart';
import EngagementDetailDrawer from '../shared/big-drawer/EngagementDetailDrawer';
import ScoreReportModal from './components/score_report_modal/scoreReportModal';




export interface ScoreDetailShowParams {
    scoreId: string;
    visible: boolean;
    to: Date;
    dateGrouping: DateGrouping;
    filters: CommonSegmentationFilters;
}


interface ScoreDetailViewProps {
    showParams: ScoreDetailShowParams;
    onClose: () => void;
}

const ScoreDetailDrawer = ({showParams, onClose}: ScoreDetailViewProps) => {


    const $translate = getService('$translate');
    const Filters = getService('Filters');
    const ScoresV2Srvc = getService('ScoresV2Srvc');
    const ErrorSvrc = getService('ErrorSvrc');
    const toaster = getService('toaster');

    //
    const [scoreId, setScoreId] = useState(null);
    const [filters, setFilters] = useState<CommonSegmentationFilters>(null);
    const [to, setTo] = useState<Date>(null);
    const [from, setFrom] = useState(null);
    const [dateGrouping, setDateGrouping] = React.useState<DateGrouping>(DateGrouping.MONTH);
    const [loading, setLoading] = React.useState<boolean>(true);

    const [results, setResults] = useState<ScoreResult[]>([]);
    const [hasEnoughActiveEmployees, setHasEnoughActiveEmployees] = useState(true);
    const [measuringNow, setMeasuringNow] = useState(false);
    const [hiImpact, setHiImpact] = useState(null);
    const [enpsImpact, setEnpsImpact] = useState(null);
    const [filtered, setFiltered] = useState<boolean>(false);
    const [status, setStatus] = useState<string>('');
    const [quality, setQuality] = useState(DataQuality.NO_DATA);
    const [valuation, setValuation] = useState(null);
    const [value, setValue] = useState(null);
    const [variation, setVariation] = useState(null);
    const [companyBenchmark, setCompanyBenchmark] = useState(null);
    const [industryBenchmark, setIndustryBenchmark] = useState(null);
    const [globalBenchmark, setGlobalBenchmark] = useState(null);
    const [ranking, setRanking] = useState(null);
    const [previousValue, setPreviousValue] = useState(null);
    const [showReportModal, setShowReportModal] = useState<boolean>(false);

    const [summaryView, setSummaryView] = useState(1);
    const [showDrawer, setShowDrawer] = useState<boolean>(false);


    const { scoreName, scoreDescription } = useScoreNames(scoreId);


    const hierarchyFiltersConfig: HierarchyFiltersConfig = useMemo(() => {
        return {
            id: 'HIERARCHY',
            type: 'hierarchyIds',
            mode: 'single',
            title: $translate.instant('AREA'),
            nonRemovable: false,
        };
    }, []);


    const groupFiltersConfig: GroupFiltersConfig = useMemo(() => {
        return {
            id: 'GROUP',
            type: 'groupIds',
            mode: 'multi',
            title: $translate.instant('GROUPS'),
            nonRemovable: false,
            groups: null,
        };
    }, []);


    const segmentFiltersConfig: SegmentFiltersConfig = useMemo(() => {
        return {
            id: 'SEGMENT',
            type: 'segmentIds',
            mode: 'multi',
            title: $translate.instant('SEGMENTED'),
            nonRemovable: false,
            segments: null
        };

    }, []);




    useEffect(() => {
        if (showParams?.visible) {
            setScoreId(showParams.scoreId);
            setShowDrawer(true);
            setFilters(showParams.filters);
            const toValue = showParams.to ? showParams.to : Filters.endDate();
            setTo( toValue);
        } else {
            setShowDrawer(false);
        }
    }, [showParams]);




    useEffect(() => {
        const newFrom = moment(to).subtract(12, 'M').toDate();
        setFrom(newFrom);
    }, [to]);



    const prepareParams = () => {
        const toParam = Dates.lastDayOfMonth(to);

        const params = {
            to: toParam,
            filters: toSegmentParams(filters?.segmentIds as any[]),
            groupId: filters?.groupIds,
            hierarchyId: filters?.hierarchyIds,
            grouping: dateGrouping,
        };
        return params;
    };

    const loadResults = () => {
        setLoading(true);
        const params = prepareParams();
        ScoresV2Srvc.stats.get(scoreId, params, (error, response: ScoreStatsResults) => {
            if (error) {
                if (ErrorSvrc.isNotEnoughActiveEmployees(error)) {
                    setHasEnoughActiveEmployees(false);
                    setResults([]);
                } else {
                    toaster.pop('error', null, $translate.instant('ERROR_LOADING_DATA'));
                }
                setLoading(false);
                return;
            }

            setStatus(response.status);
            setFiltered(response.filtered);
            setHasEnoughActiveEmployees(true);

            if (!response.results || response.results.length === 0) {
                setLoading(false);
                return;
            }
            const numResults = response.results.length;
            const current = response.results[numResults - 1];

            setQuality(current.quality);
            setValuation(current.valuation);
            setValue(Numbers.roundNumber(current.result, 1));
            setVariation(Numbers.roundNumber(current.variation, 1));
            setCompanyBenchmark(current.companyBenchmark);
            setIndustryBenchmark(current.industryBenchmark);
            setGlobalBenchmark(current.globalBenchmark);
            setRanking(current.ranking);
            setPreviousValue(
                numResults > 1
                    ? Numbers.roundNumber(response.results[numResults - 2].result, 1)
                    : undefined
            );



            setResults(response.results);
            setMeasuringNow(current.measuringNow);
            setHiImpact(current.hiImpact);
            setEnpsImpact(current.enpsImpact);

            setLoading(false);
        });
    };

    useEffect(() => {
        if (scoreId && to && from && showDrawer) {
            loadResults();
        }
    }, [showDrawer, to, from, filters, scoreId, dateGrouping]);

    return (

        <EngagementDetailDrawer
            open={showDrawer}
            onClose={onClose}
            title={


                <Stack gap={1} width={'100%'} marginBottom={1} >

                    <Box display='flex' flexDirection='row' alignItems='center'>
                        <Box flex='1'>
                            <Stack direction='row' gap={0}>
                                <Typography variant='h1' noWrap>
                                    {scoreName}
                                </Typography>
                            </Stack>

                            <Typography variant='body3' color='text.secondary'>
                                {scoreDescription}
                            </Typography>
                        </Box>

                        <Box>

                            <Stack direction='row' spacing={2}>

                                <EngagementDateBrowser
                                    to={to}
                                    dateGrouping={dateGrouping}
                                    onDateGroupingChange={setDateGrouping}
                                    showDateGrouping={true}
                                    onDateChange={setTo}
                                    showYears={true}
                                    showQuarters={true}
                                    showMonths={true}
                                />

                                <Button
                                    variant='contained'
                                    startIcon={<FileDownloadRoundedIcon />}
                                    onClick={() => setShowReportModal(true)}
                                >
                                </Button>

                            </Stack>

                        </Box>

                    </Box>

                    <HfFilterMenu
                        mode='tagging'
                        chipSize='small'
                        segmentFiltersConfig={segmentFiltersConfig}
                        hierarchyFiltersConfig={hierarchyFiltersConfig}
                        groupFiltersConfig={groupFiltersConfig}
                        selectedFilters={filters}
                        updateFilters={setFilters}
                    />
                </Stack>



            }



        >
            <Stack gap={2}>
                {measuringNow && (
                    <MeasuringNowBox
                        to={to}/>
                )}


                <WhiteBgPaper>

                    <Stack>
                        <Stack gap={1}>
                            <Typography variant='h1'>
                                <HfDateGroupingFormat format={dateGrouping} date={to}/>
                            </Typography>
                            <QualityPopoverText quality={quality}/>
                        </Stack>

                        {!hasEnoughActiveEmployees && (
                            <EmptyState
                                small={true}
                                image='images/anonymous.png'
                                message={$translate.instant('NOT_ENOUGH_EMPLOYEES', {})}
                                submessage={$translate.instant('NOT_ENOUGH_EMPLOYEES_SUBMESSAGE', {})}
                            />
                        )}

                        {hasEnoughActiveEmployees && (
                            <Grid container spacing={2}>

                                <Grid item xs={12} md={4}>
                                    <ScoreEngagementDetailOverview
                                        loading={loading}
                                        enpsImpact={enpsImpact}
                                        hiImpact={hiImpact}
                                        status={status}
                                        quality={quality}
                                        valuation={valuation}
                                        value={value}
                                        variation={variation}/>
                                </Grid>


                                <Grid item xs={12} md={6} sm={8}>
                                    <EngagementDetailEvolutionChart
                                        hasEnoughActiveEmployees={hasEnoughActiveEmployees}
                                        loading={loading}
                                        data={results}
                                        minValue={0}
                                        maxValue={10}
                                        scoreType={'STANDARD'}
                                        showCompanyBenchmark={filtered}
                                        dateGrouping={dateGrouping}/>
                                </Grid>

                                <Grid item xs={12} md={2} sm={12}>
                                    <EngagementRankingBox
                                        loading={loading}
                                        scoreId={scoreId}
                                        ranking={ranking}
                                        type={'STANDARD'}/>


                                    <Box mt={2} textAlign={'center'}>
                                        <BenchmarkBox
                                            loading={loading}
                                            value={value}
                                            companyBenchmark={companyBenchmark}
                                            globalBenchmark={globalBenchmark}
                                            industryBenchmark={industryBenchmark}
                                            filtered={filtered}/>
                                    </Box>

                                </Grid>
                            </Grid>
                        )}



                    </Stack>
                </WhiteBgPaper>


                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <WhiteBgPaper>
                            <ScoreDetailFactorsList
                                hasEnoughActiveEmployees={hasEnoughActiveEmployees}
                                results={results}
                                scoreId={scoreId}
                                dateGrouping={dateGrouping}/>
                        </WhiteBgPaper>

                        <WhiteBgPaper sx={{marginTop: 2}}>

                            <Box display={'flex'}
                                width={'100%'}
                                alignItems={'center'}
                                justifyContent={'center'}>
                                <ButtonGroup variant='outlined' aria-label='Basic button group'>
                                    <Button
                                        variant={summaryView === 1 ? 'contained' : 'outlined'}
                                        onClick={() => setSummaryView(1)}
                                    >
                                        {$translate.instant('ENGAGEMENT_DETAIL_INSIGHTS')}
                                    </Button>
                                    <Button
                                        variant={summaryView === 2 ? 'contained' : 'outlined'}
                                        onClick={() => setSummaryView(2)}
                                    >
                                        {$translate.instant('ENGAGEMENT_DETAIL_POSITIONING')}
                                    </Button>
                                </ButtonGroup>
                            </Box>

                            <Box sx={{ marginTop: 3 }}>

                                {summaryView === 1 && (

                                    <EngagementPrioritiesBox
                                        to={to}
                                        dateGrouping={dateGrouping}
                                        scoreId={scoreId}
                                        filters={filters}
                                        showFiltering={false}
                                        onViewDetailClick={() => {}}
                                        onViewDetailLabel={''}
                                    />
                                )}
                                {summaryView === 2 && (

                                    <ScorePositioningBox
                                        score={scoreId}
                                        to={to}

                                        dateGrouping={dateGrouping}
                                        navigateToDetail={(scoreId: string, params) => {}}

                                    />
                                )}

                            </Box>


                        </WhiteBgPaper>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <WhiteBgPaper sx={{height: '100%'}}>
                            <EngagementScoresFeedbackBox
                                hasEnoughActiveEmployees={hasEnoughActiveEmployees}
                                mode={'SCORE'}
                                scoreId={scoreId}
                                dateGrouping={dateGrouping}
                                to={to}
                                filters={filters}/>
                        </WhiteBgPaper>
                    </Grid>
                </Grid>


            </Stack>

            <ScoreReportModal
                scoreId={scoreId}
                to={to}
                show={showReportModal}
                segmentIds={filters?.segmentIds}
                groupIds={filters?.groupIds}
                hierarchyIds={filters?.hierarchyIds}
                onClose={() => {
                    setShowReportModal(false);
                }}></ScoreReportModal>
        </EngagementDetailDrawer>


    );
};

export default ScoreDetailDrawer;
