import React, { useEffect, useRef, ReactNode } from 'react';

import CommentInappropriateBox from '../comment-inappropiate-box/CommentInappropriateBox';
import {HfBannerReact} from '../../../hf-banner/hfBanner';
import StyleUtils from '../../../../../utilities/style.utils';
import {CommentBase} from '../../types';
import { getService } from 'reactInAngular';
import {Box, Chip, Stack, Tooltip, Typography} from '@mui/material';
import HFPulseMark from '../../../hf-pulse-mark/hf-pulse-mark';
import HFTag from '../../../hf-tag/hf-tag';
import CommentTextBox from '../../../hf-styled-components/CommentTextBox';
import {ImageIcon} from '../../../hf-styled-components/ImageIcon';
import moment from 'moment';
import HfCommentImage from '../comment-image/CommentImage';
import HfUserSmall from '../../../../components/_migrated/user-small/hfUserSmall';


interface CommentMainProps {
    comment: CommentBase;
    hideType?: boolean;
    replyOf?: string;
    feedbackTypeText?: string;
    postHeaderZone?: ReactNode;
    reactionsZone?: ReactNode;
    preCommentZone?: ReactNode;
    postCommentZone?: ReactNode;
    onCommentUpdated?: (err: any, updatedCommentId: string) => void;
}

const CommentMain: React.FC<CommentMainProps> = ({
    comment,
    hideType,
    replyOf,
    feedbackTypeText,
    postHeaderZone,
    reactionsZone,
    preCommentZone,
    postCommentZone,
    onCommentUpdated,
}) => {
    const runningRef = useRef<NodeJS.Timeout | null>(null);

    const $translate = getService('$translate');
    const Comments = getService('Comments');

    const commentVisible = () => {
        if (!runningRef.current) {
            runningRef.current = setTimeout(() => {
                if (!comment.hasBeenSeen && !replyOf) {
                    Comments.markAsSeen(comment.id, (err: any, result: { hasBeenSeen: boolean }) => {
                        if (!err) {
                            comment.hasBeenSeen = result.hasBeenSeen;
                        }
                        runningRef.current = null;
                    });
                }
            }, 3000);
        }
    };

    useEffect(() => {
        return () => {
            if (runningRef.current) {
                clearTimeout(runningRef.current);
            }
        };
    }, []);

    return (
        <Box component={'article'}
            in-view={commentVisible()}>
            <Box component='header'>
                <Stack direction='column' gap={1}>
                    {!hideType && (
                        <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'space-between'}>
                            <Stack direction='row' gap={1} alignItems={'center'}>
                                <ImageIcon size={'medium'} src={comment.feedbackTypeImage} alt='Feedback Type'/>
                                <Typography variant='body2' className='font-bold'>
                                    {feedbackTypeText}
                                </Typography>
                            </Stack>

                            {!comment.hasBeenSeen && (
                                <HFPulseMark/>
                            )}
                        </Stack>

                    )}
                    {postHeaderZone}
                    <Typography variant='caption'>
                        {$translate.instant('AREA')}: {comment.hierarchyName}
                    </Typography>
                    <Typography variant='caption'>
                        {moment(comment.date).format('DD MMM YYYY hh:mm')}
                    </Typography>
                    {comment.privated && (
                        <Tooltip title={$translate.instant('PRIVATE_COMMENT_DESCRIPTION')}>
                            <Box>
                                <Chip
                                    size='small'
                                    variant='outlined'
                                    label={<Typography variant='caption'>{$translate.instant('PRIVATE_COMMENT')}</Typography>}
                                    color='warning'
                                />
                            </Box>
                        </Tooltip>
                    )}
                </Stack>
            </Box>

            <Box component='main' marginTop={2}>
                {comment.flaggedAnonymous && (
                    <Tooltip title={$translate.instant('ANONYMOUS_COMMENT_DESCRIPTION')}>
                        <HfBannerReact backgroundColor={StyleUtils.chartBg}>
                            <Typography variant='body3'>
                                {$translate.instant('ANONYMOUS_COMMENT')}
                            </Typography>

                        </HfBannerReact>
                    </Tooltip>

                )}

                {!comment.inappropriate && !comment.flaggedAnonymous && !comment.refereed && (
                    <Stack gap={1}>
                        {preCommentZone}

                        {comment.attachments && (<HfCommentImage attachments={comment.attachments}/>)}

                        <CommentTextBox text={comment.comment}/>
                        {postCommentZone}
                        {comment.signerId && (
                            <HfUserSmall userId={comment.signerId} />
                        )}
                    </Stack>
                )}

                {(comment.inappropriate || comment.refereed) && !comment.flaggedAnonymous && (
                    <CommentInappropriateBox
                        comment={comment}
                        replyOf={replyOf}
                        self-handle={true}
                        inline-style={false}
                        inappropriate-callback={onCommentUpdated}
                    />
                )}
            </Box>

            <Box component='footer' marginTop={2}>
                {reactionsZone}
            </Box>
        </Box>
    );
};

export default CommentMain;
