import Dates from '../../../utilities/date.utilities';
import { VOECategoryStats } from '../../types/feedback';
import { APISettings, doGetAsync } from './axiosWrapper';


const statsBaseUrl = '/company/stats/comments';

interface CommonParams {
    from?: string;
    to?: string;
    groupBy?: string;
    groupIds?: string[];
    questionId?: string;
    hierarchyIds?: string[];
    filters?: string;
    commentText?: string;
    hasCompanyReply?: boolean;
    hasReplies?: boolean;
    hasConversation?: boolean;
    hasSignature?: boolean;
    ratings?: number[];
    tags?: string[];
    feedbackTypes?: string[];
    sameAuthorAs?: string;
    excludePrivate?: boolean;
    privated?: boolean;
    containerId?: string;
}

interface CommentStatsParams extends CommonParams {
    statTypes: string[];
}

export interface VoiceOfEmployeeParams extends CommentStatsParams {
    from?: string;
    to?: string;
    groupBy?: string;
    groupIds?: string[];
    questionId?: string;
    hierarchyIds?: string[];
    filters?: string;
    commentText?: string;
    hasCompanyReply?: boolean;
    hasReplies?: boolean;
    hasConversation?: boolean;
    hasSignature?: boolean;
    ratings?: number[];
    tags?: string[];
    feedbackTypes?: string[];
    sameAuthorAs?: string;
    excludePrivate?: boolean;
    privated?: boolean;
    containerId?: string;
}


export const getCommentsStats = (params: CommentStatsParams) => {
    const _params = { ...params };
    if (_params.to) {
        _params.to = Dates.toAPIFormat(params.to, APISettings.apiDateFormat);
    }
    if (_params.from) {
        _params.from = Dates.toAPIFormat(params.from, APISettings.apiDateFormat);
    }

    const url = `${statsBaseUrl}`;
    return doGetAsync(url, _params);
};



export const getVoiceOfEmployee = (params: VoiceOfEmployeeParams): Promise<VOECategoryStats[]> => {
    const _params = { ...params };
    if (_params.to) {
        _params.to = Dates.toAPIFormat(params.to, APISettings.apiDateFormat);
    }
    if (_params.from) {
        _params.from = Dates.toAPIFormat(params.from, APISettings.apiDateFormat);
    }

    const url = `${statsBaseUrl}/voe`;
    return doGetAsync(url, _params);
};



