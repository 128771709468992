import * as React from 'react';
import { Box, Typography, Chip, Stack } from '@mui/material';
import { getService } from 'reactInAngular';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EmptyState from '../../../../shared/components/_migrated/empty_state/empty_state';

interface FeedbackTagsComponentProps {
    activeTags: string[];
    onTagsChange: (tags: string[]) => void;
    availableTags?: Array<{title: string; averageRating?: number}>;
}

const FeedbackTagsComponent: React.FC<FeedbackTagsComponentProps> = ({ activeTags, onTagsChange, availableTags = [] }) => {

    const $translate = getService('$translate');

    const handleTagClick = (tag: string) => {
        const index = activeTags.indexOf(tag);
        if (index === -1) {
            onTagsChange([...activeTags, tag]);
        } else {
            const newTags = [...activeTags];
            newTags.splice(index, 1);
            onTagsChange(newTags);
        }
    };

    const resetTags = () => {
        onTagsChange([]);
    };


    const filteredTags = availableTags.filter(tag =>
        !activeTags.includes(tag.title)
    );


    if (availableTags.length === 0) {
        return (
            <EmptyState
                image='images/no_feedback_engagement.svg'
                small
                message={$translate.instant('FEEDBACK_RECEIVED_TALKING_ABOUT_NO_DATA')}
            />
        );
    }

    return (

        <Stack direction='column' spacing={2}>
            {activeTags.length > 0 && (
                <Box display={'flex'} flexDirection={'row'} alignItems='center' gap={0.5} flexWrap={'wrap'}>
                    <Chip
                        icon={<HomeIcon />}
                        label=''
                        onClick={resetTags}
                        color='primary'
                        variant='outlined'
                        size='small'
                    />
                    {activeTags.map((tag) => (
                        <React.Fragment key={tag}>
                            <ChevronRightIcon fontSize='small' color='action' />
                            <Chip
                                label={tag}
                                onDelete={() => handleTagClick(tag)}
                                color='primary'
                                variant='filled'
                                size='small'
                            />
                        </React.Fragment>
                    ))}
                </Box>
            )}

            <Box display={'flex'} flexDirection={'row'} alignItems='center' gap={0.5} flexWrap={'wrap'}>
                {filteredTags.map((tag) => (
                    <Chip
                        key={tag.title}
                        label={tag.title}
                        onClick={() => handleTagClick(tag.title)}
                        color='default'
                        variant='outlined'
                        size='small'
                    />
                ))}
            </Box>
        </Stack>
    );
};

export default FeedbackTagsComponent;
